import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-form-textarea',
    templateUrl: './textarea.component.html',
    providers: []
})

export class TextareaComponent implements OnInit {

    @Input() item: any;
    @Input() itemQuestions: any;
    @Input() itemRequired: any;
    @Input() itemName: any;
    @Input() itemDependencies: any;
    @Input() itemLabel: any;

    depCount: any = [];

    constructor() { }

    ngOnInit() {


    }

}