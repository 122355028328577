import { Component, OnInit } from '@angular/core';
import {AppComponent} from '../../app.component';
import {ApiService} from '../../shared/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['../login/login.component.scss']
})
export class RecoverComponent implements OnInit {
  projectIcon:any;
  recoverForm: FormGroup;
  submitted = false;
  loading = false;

  constructor(
    private svApi: ApiService,
    private appComponent: AppComponent,
    private formBuilder: FormBuilder
  )
  {
    this.projectIcon=sessionStorage.getItem('projectIcon');
    this.appComponent.showHeader =false;

  }

  ngOnInit(): void {
    this.appComponent.showHeader =false;
    this.recoverForm = this.formBuilder.group({
      username: ['', Validators.required]
  });
  }

  // convenience getter for easy access to form fields
  get f() { return this.recoverForm.controls; }


  onSubmit(){
    this.submitted = true;
    if (this.recoverForm.invalid) {
      return;
    }
    else{
      //console.log("valid")
      //console.log(this.recoverForm.value.username)
      let recover={emailOrUserName:this.recoverForm.value.username};
      this.svApi.resetPwSendToken(recover).subscribe(
        result=>{
          //console.log(result)
        },
        error=>
        {
          Swal.fire({
            icon: 'error',
            title: 'Erro ao enviar email',
            text: 'Erro ao enviar email de reposição. Por favor, tente novamente.',
          })
        }
      )
    }

  }
}
