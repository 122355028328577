import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../shared/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {AppComponent} from '../../app.component';
import {AccountService} from '../services/authentication.service';
import {UserService} from '../services/user.service';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  public successLogin: boolean = false;
  projectId:any;
  projectIcon:any;
  load:false;

  constructor(
    private svApi: ApiService,
    private router: Router,
    private authenticationService: AccountService,
    private appComponent: AppComponent,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  )
  {
    this.appComponent.showHeader =false;
  }

  ngOnInit(): void {
    this.appComponent.showHeader =false;
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
  });
    this.projectIcon=sessionStorage.getItem('projectIcon');
    this.projectId=localStorage.getItem('project');

  }

  // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

 async onSubmit() {
  let login= {email:this.f.username.value,password:this.f.password.value}
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
      }
    else{
      try{
        const result = await this.authenticationService.loginByUserPw(this.projectId,login);

        if (result==true)
        {
          sessionStorage.setItem("logged", 'true')
          this.successLogin = true;
          this.loading = false;
          this.svApi.getSignedTerm().subscribe(
            result=>{
              let signed=result.data;
              if(signed)
              {
                sessionStorage.setItem('signedTerm','true')
                this.router.navigate(['home']);
              }
              else{
                sessionStorage.setItem('signedTerm','false')
                this.router.navigate(['ci-auth']);
              }

            })
        }
////console.log(`Login efetuado: ${result}`);
      }
      catch (error)
      {
        Swal.fire({
          icon: 'error',
          title: 'Login inválido! ',
          text: 'Utilize o seu username e password',
        })
          this.loading = false;
      }
    }

  }

}

