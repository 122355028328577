<!-- row 1 -->
<div class="box grey" style="padding-top: 2rem;">
  <div class="row" style="padding-bottom: 1rem;">
   
    <label class="questionLbl"> <i class="fas fa-th faIcon yellow paddingIconR"></i> {{item.label}} 
      <b *ngIf="itemRequired" class="text-danger">*</b>
  </label>
  </div>
  <div class="row smallWindow" style="margin-top: 1rem;margin-bottom: 1rem;">
    <div class="col-12 col-lg-12 col-xl-12">
      <span><b>Instruções</b></span><br>
      <span *ngFor="let c of itemColumns; let i=index;">
        <span *ngIf="c.label!=''">
          <b>{{c.value}}</b>: "{{c.label}}"; 
        </span>
      </span>
    </div>
  </div>
  <!-- row 2 -->
  <div style="width: 100%;">
    <div class="row">
      <div class="col-4 noPadding"></div>
      <div class="col-2">
  
      </div>
      <div class="col noPadding cScaleTitle" *ngFor="let c of itemColumns">
        {{c.label}}
      </div>
    </div>
    <!-- row large window -->
    
    <div class="row rItems largeWindow" *ngFor="let r of itemRows; let i=index;">
      <div *ngIf="i==10||i==20||i==30||i==40||i==50||i==60||i==70||i==80||i==90||i==100" class="row" style="background-color: white; width: 100%;">
        <div class="col-4 noPadding" ></div>
        <div class="col-2">
  
        </div>
        <div class="col noPadding cScaleTitle" *ngFor="let c of itemColumns" >
          {{c.label }}
        </div>
      </div>  
  
      <div [id]="'radio_'+r.id" class="col-4" style="padding-left: 10px; display: flex;flex-direction: column;justify-content: center;">
        <span>{{r.label}}</span>
      </div>
  
      <div class="col-2">
        <img *ngIf="r.haveImage" [src]="r.ImageBase64" style="max-width: 150px;max-height: 150px;" (click)="openImage(template, r.ImageBase64)" class="img-responsive imgView">
      </div>
  
      <div style="display: flex;flex-direction: column;justify-content: center; align-items: center;" class="col noPadding cScale" *ngFor="let c of itemColumns">
        <input type="radio" [value]="c.cod" name="{{r.qSynthesis}}" [(ngModel)]="r.valueLine" [required]="item.required"
          [ngModelOptions]="{standalone: true}" [disabled]="submitted">
      </div>
    </div>
    <!-- row small window -->
    <div class="row rItems smallWindow" *ngFor="let r of itemRows; let i=index;">
      <div class="col-12">
        <div class="row">
          <div class="col">
            <span style="display: table-row">{{r.label}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col cScale" *ngFor="let c of itemColumns">
            <div class="row">
              <div class="col">
                {{c.value}}<br>
              </div>
            </div>
            <div class="row">
              <div class="col" style="display: flex;flex-direction: column;justify-content: center; align-items: center;">
                <input type="radio" [value]="c.value" name="{{r.qSynthesis}}" [(ngModel)]="r.valueLine"
                  [required]="item.required" [ngModelOptions]="{standalone: true}">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>


  <ng-template #template>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Image</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true" style="font-size: 2rem;
        font-weight: initial;">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="text-align: center;">
        <img [src]="imageModel" style=" max-height: 100%;max-width: 100%;cursor: pointer; ">
    </div>
    <div class="modal-footer">

    </div>
   
  </ng-template>