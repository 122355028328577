<div class=" container boxSignUp">
    <div class="row justify-content-center">
      <div class="col" style="padding: 0;">
        <form [formGroup]="signUpForm" (ngSubmit)="onSubmit()">
          <!-- box -->
          <div class="formInputBox">
            <!-- img -->
            <div class="row" style="padding: 4.5rem;">
              <div class="col" style="text-align: center;">
                  <img style="width: 200px;" [src]="projectIcon" />
              </div>
            </div>
            <!-- content form -->
            <div class="row" style="padding: 1rem;">
              <div class="col" style="text-align: center;">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                      <span class="input-group-text"
                      style="padding:5px !important; border: 0 !important; background-color: inherit !important;">
                      <i style="font-size:40px;" class="bi bi-person-circle"></i>
                    </span>
                  </div>

                  <input name="username" placeholder="E-mail" type="email" email="true" formControlName="username" class="form-control" required
                    [ngClass]="{ 'is-invalid': submitted &&f.username.errors }" />
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                      <span style="text-align: center;color:#dc3545" *ngIf="(f.username.errors?.pattern|json)!=null">Utilizador inválido.</span>
                      <div *ngIf="f.username.errors.required">Password requerida</div>
                    </div>
                </div>
                <div class="input-group mb-3" style="">
                  <div class="input-group-prepend">
                      <span class="input-group-text"
                      style="padding:5px !important; border: 0 !important; background-color: inherit !important;">
                      <i style="font-size:40px;" class="bi bi-key-fill"></i>
                    </span>
                  </div>

                  <input placeholder="Password" type="password" formControlName="password" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <span style="text-align: center;color:#dc3545" *ngIf="f.password.errors.pattern">A Password deve conter pelo menos 8 caracteres, 1 maiúscula, 1 minúscula, 1 caractere especial (#,?,!,@,$,%,^,&,*,-) e 1 número.</span>
                    <div *ngIf="f.password.errors.required">Password requerida</div>
                  </div>
                </div>
                <div class="input-group mb-3" style="">
                    <div class="input-group-prepend">
                        <span class="input-group-text"
                        style="padding:5px !important; border: 0 !important; background-color: inherit !important;">
                        <i style="font-size:40px;" class="bi bi-key-fill"></i>
                      </span>
                    </div>
                    <input placeholder="Confirmação password" type="password" formControlName="password2" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && passwordNotMatch }" />
                    <div *ngIf="submitted && f.password2.errors" class="invalid-feedback">
                      <div *ngIf="f.password2.errors.required">Password requerida</div>

                    </div>
                    <div *ngIf="submitted && passwordNotMatch" class="invalid-feedback">
                      <span style="text-align: center;color:#dc3545">As passwords não são iguais</span>
                    </div>
                  </div>

                  <div class="input-group" style="max-height: 175px;">
                    <div class="info">
                        <p>Por favor preencha todos os campos necessários.Todos os dados são criptografados para garantir a conformidade com as políticas de proteção de dados. A palavra-passe deve conter no mínimo 8 caracteres, sendo 1 maiúscula, 1 minúscula, 1 caractere especial (#,?,!,@,$,%,^,&,*,-) e 1 número.</p>
                    </div>

                  </div>

                <div class="row" style="">
                  <div class="col-12" style="text-align: center;">
                    <button [disabled]="loading" class="btForm">
                      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                     Registar
                    </button>
                  </div>
                </div>

              </div>
            </div>

          </div>



        </form>
      </div>
    </div>
  </div>

  <div class=" container box3">
    <div class="formInputBox">
        <!-- box -->

        <div class="row" style="padding: 2rem;">
          <div class="col" style="text-align: center; color: #5E5D5D;font-size: 16px;">
              Já está registado? <a style="text-decoration: none;font-size: 16px; font-weight: bold;" routerLink="/auth/login">Clique aqui!</a>
          </div>
      </div>
    </div>
</div>
