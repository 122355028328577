/*
Call element based on 'itemType' and build the element
Check if item have helper; check if item have image; check if is required, and build the design item
*/


import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-form-element',
    templateUrl: './form-element.component.html',
    styleUrls: ['../../app.component.scss'],
    providers: []
})

export class FormElementComponent implements OnInit {

    @Input() item: any;
    @Input() itemType: any;
    @Input() submitted: boolean;

    constructor() { }

    ngOnInit() {
    }

}