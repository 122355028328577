<!-- <div class="!sucesslogin"> -->
    <div class=" container box1">
        <div class="row justify-content-center">
          <div class="col" style="padding: 0;">
            <form [formGroup]="recoverForm" (ngSubmit)="onSubmit()">
              <!-- box -->
              <div class="formInputBox">
                <!-- img -->
                <div class="row" style="padding: 4.5rem;">
                  <div class="col" style="text-align: center;">      
                      <img style="width: 200px;" [src]="projectIcon" />
                  </div>
                </div>
                <!-- content form -->
                <div class="row" style="padding: 1rem;">
                  <div class="col" style="text-align: center;">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                          <span class="input-group-text"
                          style="padding:5px !important; border: 0 !important; background-color: inherit !important;">
                          <i style="font-size:40px;" class="bi bi-person-circle"></i>
                        </span>
                      </div>
                      <input placeholder="E-mail" type="text" formControlName="username" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                      <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">Utilizador requerido</div>
                      </div>
                    </div>

           
                    <div class="row" style="padding-top: 1.5rem;">
                      <div class="col-12" style="text-align: center;">
                        <button [disabled]="loading" class="btForm">
                          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                         RECUPERAR PASSWORD
                        </button>
                      </div>
                    </div>
                 
                  </div>
                </div>
               
              </div>

             
           
            
            </form>
          </div>
        </div>
      </div>


      <div class=" container box2">
        <div class="formInputBox">
            <!-- box -->

            <div class="row" style="padding: 2rem;">
              <div class="col" style="text-align: center; color: #5E5D5D;font-size: 16px;">
                  Ainda não está registado? <a style="text-decoration: none;font-size: 16px; font-weight: bold;" routerLink="/auth/signup">Registe-se!</a>
              </div>
          </div>  
        </div>
    </div>
  <!-- </div> -->
    