import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-form-title',
    templateUrl: './title.component.html',
    styleUrls: ['./title.component.scss'],

    providers: []
})

export class TitleComponent implements OnInit {

    @Input() itemLabel: any;

    constructor() { }

    ngOnInit() {
    }

}