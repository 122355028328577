import { Component, Input, OnInit } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
defineLocale('pt-br', ptBrLocale); 

@Component({
    selector: 'app-form-date',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.scss'],
    providers: []
})

export class DateComponent implements OnInit {
    locale = 'pt-br';

    @Input() item: any;
    @Input() itemRequired: any;
    @Input() submitted: boolean;

    colorTheme = 'theme-blue';
    bsConfig: Partial<BsDatepickerConfig> = Object.assign({locale: this.locale}, { containerClass: this.colorTheme },{ dateInputFormat: 'YYYY/MM/DD' });



    constructor() { }

    ngOnInit() {

    }


}