
<!-- row 1 -->
<div class="box grey" style="padding-top: 2rem;">
<div class="row" >
  <i class="far fa-calendar-alt faIcon yellow paddingIconR"></i>
  <label class="questionLbl">{{item.label}} 
    <b *ngIf="itemRequired" class="text-danger">*</b>
</label>
<button *ngIf="item.help.length!=0" style="background:none; border:none; color: #233a80; height: 10px;" 
tooltip="{{item.help}}">
<i class="far fa-question-circle"></i>
</button>
</div>

<!-- row 2 -->
<div style="width: 100%;">
  <div class="row">
    <div class="row">
        <div class="col-xs-12 col-12 col-md-4 form-group">
          <input type="text" bsDatepicker #dp="bsDatepicker" [disabled]="submitted" [bsConfig]="bsConfig"
                 placeholder="aaaa/mm/dd"
                 class="form-control inputT"
                 style="width: 133px;"
                 [(ngModel)]="item.value"
                 bsDatepicker
                 placement="right">
        </div>
    
    </div>
  </div>

</div>