<div class="box">
   
    <label class="questionLbl"> <i class="far fa-check-square faIcon yellow paddingIconR"></i> {{itemLabel}} 
        <b *ngIf="itemRequired" class="text-danger">*</b>
    </label>
    <button *ngIf="item.help.length!=0" style="background:none; border:none; color: #233a80;" 
    tooltip="{{item.help}}">
    <i class="far fa-question-circle"></i>
</button>
    <p>
    <img *ngIf="item.haveImage" [src]="item.ImageBase64" (click)="openImage(template, item.ImageBase64)" style="max-width: 300px;max-height: 300px;cursor:zoom-in;" class="img-responsive">
    </p>
    <div *ngFor="let v of item.values" class="inline-form-group boxRandC ">
     
            <input class="checkbox" type="checkbox" 
                [value]="v.value" 
                [name]="itemName"
                [disabled]="submitted"
                (click)="toggleValue(v)">
                <img *ngIf="v.haveImage" (click)="openImage(template, v.ImageBase64)" [src]="v.ImageBase64"  class="img-responsive imgView">
                <label class="cust-check-bx " style="margin-bottom:0px; color:#5E5D5D; margin-left: 0.5rem;" >
                {{v.label}}
     
        </label>
    </div>
</div>

<ng-template #template>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Image</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true" style="font-size: 2rem;
        font-weight: initial;">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="text-align: center;">
        <img [src]="imageModel" style=" max-height: 100%;max-width: 100%;cursor: pointer; ">
    </div>
    <div class="modal-footer">

    </div>
   
  </ng-template>
