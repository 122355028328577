import { Component, Input, OnInit , TemplateRef} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
    selector: 'app-form-matrix',
    templateUrl: './matrix.component.html',
    styleUrls: ['./matrix.component.scss'],
    providers: []
})

export class MatrixComponent implements OnInit {

    @Input() itemColumns: any;
    @Input() itemRequired: any;
    @Input() itemRows: any;
    @Input() item: any;
    @Input() submitted: boolean;


    depCount: any = [];
    template: TemplateRef<any>;
    modalRef: BsModalRef;
    imageModel:any;

    constructor(private modalService: BsModalService) { }

    ngOnInit() {

    }
    openImage(template: TemplateRef<any>, image) 
    {
        this.imageModel=image;
            this.modalRef = this.modalService.show(
              template
            );
    }
}