/*
FORM:  create base to form page, and call form-builder to build the elements
Funcionalidades: get form , check configs, limits and images; execute validations, calculate formulas, create feedbacks and build answers; submit form
*/

import { ViewportScroller, DatePipe, formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild, OnChanges, ComponentFactoryResolver, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {ApiService} from '../shared/services/api.service';
import {DataService} from '../shared/services/data.service';
import {FormBuilderComponent} from '../forms/form-builder.component';

import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  haveSubmissionMsg=false;
  submissionMsg='';

  newDepSection:any;

  havePontuation=false;
  pontuationsArray:any=[];
  arrayFeedbacks:any=[];
  formula:any;
  scoringItems:any=[];

  countNot: number=0;
  haveLimit:boolean=false;
  limitValue:any;
  limitNumber:any;
  btSubmit:boolean=true;
  end:boolean=false;
  formData: any = {};
  reCaptchaKey: string;

  userID: any;

  private phaseForm:any;


  // initialize answers array empty before sending to server
  private fillAnswer: any = [];
  //
  public submitted: boolean = false;
  //
  public scoreString: any = [];
  //
  formID:any
  form:any;
  loadfields:boolean=false;

  @ViewChild("scrollDiv", { static: true }) scrollTo: ElementRef;

  constructor(

    private viewportScroller: ViewportScroller,
    private activatedRoute: ActivatedRoute,
    private _elementRef: ElementRef,
    private router: Router,
    private svApi: ApiService, private svData: DataService,
    private sanitizer: DomSanitizer

  ) {
    this.userID=sessionStorage.getItem("userID");

    this.phaseForm = this.activatedRoute.snapshot.data.formData.data;
    //console.log("phaseForm: ",this.phaseForm)
        this.form=this.phaseForm.form;

        //save configurations
        let config= JSON.parse(this.form.config);

        //save form itens
        this.svApi.getJsonByID(this.phaseForm.formId).subscribe(
          result=>
          {
            this.formData= JSON.parse(result.data)
//console.log("this.formData: ",this.formData)

        //iterate config for check it have limit or pontuation
        for(let c of config)
        {
          if (c.name=="limit" && c.bool==true)
          {
            this.haveLimit=true;
            this.limitValue=config[0].value;
            this.limitNumber=config[0].number;
          }
          else if(c.name=="pontuation" && c.bool==true)
          {

            this.havePontuation=true;
            this.pontuationsArray=c.scale;

          }
          else if(c.name=="submissionMsg" && c.bool==true)
          {

            this.haveSubmissionMsg=true;
            this.submissionMsg=c.string;

          }

        }
//console.log(this.haveSubmissionMsg + this.submissionMsg)

        //iterate itens for check it have dependencies or images
//console.log("this.formData.attributes: ",this.formData.attributes)
        for(let f of this.formData.attributes)
        {
          if(f.dependencies==true)
          {
            f.visible=false;
          }
          else{
            f.visible=true;
          }
          if (f.haveImage==true)
          {

            this.getImage(f);
          }
          //check if item is matrix or other
          if (f.type=="matrix")
          {

            for(let r of f.values[1].rows)
            {
              if(r.haveImage==true)
              {
                this.getImage(r);
              }
            }
          }
          else if(f.type=="checkbox"||f.type=="radio"||f.type=="autocomplete"||f.type=="orderItem")
          {
            for(let v of f.values)
            {
              if(v.haveImage==true)
              {
                this.getImage(v);
              }
            }
          }
        }

        if(this.formData.depSection)
        {
          for(let f of this.formData.depSection.attributes)
          {
            if(f.dependencies==true)
          {
            f.visible=false;
          }
          else{
            f.visible=true;
          }
          if (f.haveImage==true)
          {

            this.getImage(f);
          }
          //check if item is matrix or other
          if (f.type=="matrix")
          {

            for(let r of f.values[1].rows)
            {
              if(r.haveImage==true)
              {
                this.getImage(r);
              }
            }
          }
          else if(f.type=="checkbox"||f.type=="radio"||f.type=="autocomplete"||f.type=="orderItem")
          {
            for(let v of f.values)
            {
              if(v.haveImage==true)
              {
                this.getImage(v);
              }
            }
          }
          }
        }
        this.loadfields=true;
        },
        error=>{
          Swal.fire({
            icon: 'error',
            title: 'Erro! ',
            text: 'Erro ao obter o formulário',
          })
        }
      )


  }




  ngOnInit() {
    setTimeout(() => {
      this.scrollTo.nativeElement.scrollIntoView({ behavior: 'smooth' });
    });


  }



//calculate scales, iterate scales and calculte formulas
  calculatePontuations()
  {
    for(let scale of this.pontuationsArray)
    {
      scale.result=this.calculateFormula(scale.formula)
    }
    this.createFeedbacks();
  }

//iterate items of the scoringItems, verify if is include in formula, and replace label for value and calculate formula
calculateFormula(formula:any)
{

    let newformula:any=formula;
    this.createArrayScoringItems(this.formData.attributes)

    for(let sItem of this.scoringItems)
    {
      //.split(/\W+/).includes is need to check if the concrete string is in String; if use only include() occur a error, ex: PHP_1 and PHP_10-PHP_19, match with PHP_1
     if(formula.split(/\W+/).includes(sItem.qSynthesis))
      {
        if(sItem.type=="radio" || sItem.type=="number")
        {
          newformula= newformula.replace(sItem.qSynthesis, sItem.value);
        }
        else{
          newformula= newformula.replace(sItem.qSynthesis, sItem.valueLine);
        }
      }
    }


    //function for calculate formula
    let result= eval(newformula)

    let decimal= +(result.toFixed(3));

    //fixe with 3 decimal places
    return decimal;
}

//iterate scales, iterate cut off points, check if the operator is "=", if true verify if value of cut point is equal to result;
//else check if result are between valueStart and valueEnd
//push messages in arrayFeedbacks
createFeedbacks()
{

  for(let scale of this.pontuationsArray)
  {
    if(this.form.showFormExpression=='true')
    {
      for(let cut of scale.cutOffPoints)
      {
       if (cut.operatorStart=='=')
        {

          if(cut.valueStart==scale.result)
          {
            let message={scalename: scale.name, scalenameToUser: scale.scalenameToUser,scalemessage: cut.message , pontuation: scale.result}
            this.arrayFeedbacks.push(message)
          }
        }
        else
        {
          ////console.log(scale.result,'>', cut.valueStart, '&&', scale.result,'<',cut.valueEnd)
          if(scale.result>cut.valueStart && scale.result<cut.valueEnd)
          {
            let message={scalename: scale.name, scalenameToUser: scale.scalenameToUser, scalemessage: cut.message, pontuation: scale.result}
            this.arrayFeedbacks.push(message)
          }

        }
      }
    }
    else{
      let message={scalename: scale.name, scalenameToUser: scale.scalenameToUser, scalemessage: 'empty', pontuation: scale.result}
      this.arrayFeedbacks.push(message)
    }


  }
}

  //create arrayScoring only radio and matrix items can score
 createArrayScoringItems(formData){
  if(this.scoringItems.length==0)
  {
    let count=0;
    for(let item of formData)
    {
      if (item.type=="radio"||item.type=="number")
      {
        this.scoringItems.push(item);
      }
      else if(item.type=="matrix")
      {
        for(let itemMatrix of item.values[1].rows)
        {
          this.scoringItems.push(itemMatrix);
        }
      }

    }
  }
}

  //get the image of item
  getImage(item)
  {
        this.svApi.getFileByID(item.imageID).subscribe(
          result=>{
            let objectURL = URL.createObjectURL(result);
            item.ImageBase64 = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          },
          error=>{
            Swal.fire({
              icon: 'error',
              title: 'Erro! ',
              text: 'erro ao obter a imagem',
            })
          }
        )

  }


  //exit form
  exitForm() {
    Swal.fire({
      title: "Tem a certeza que pretende sair?",
      html: "Todas as respostas serão perdidas.",
      showDenyButton: true,
      showCancelButton: false,
      allowOutsideClick: false,
      confirmButtonColor: '#41BBC9',
      denyButtonColor: '#E5004E',
      confirmButtonText: "Sim",
      denyButtonText: "Não",
      customClass: {
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        denyButton: 'order-3',
      }
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(["/"]);
      } else if (result.isDenied) {
        // does nothing
      }
    })
  }

  // function that does the math not use the moment, only necessary if use formulas like a FF project
  parse(leftHandler, operation, righHandler) {
    var signs = ["*", "+", "-", "/"];
    var funcs = [multiply, add, minus, divison];
    var tokens = [leftHandler, operation, righHandler];
    for (var round = 0; round < signs.length; round++) {
      for (var place = 0; place < tokens.length; place++) {
        if (tokens[place] == signs[round]) {
          var a = Math.round(tokens[place - 1] * 100 + Number.EPSILON) / 100;
          var b = Math.round(tokens[place + 1] * 100 + Number.EPSILON) / 100;
          var result = funcs[round](a, b);

          // tokens[place - 1] = result.toString();

          // tokens.splice(place--, 2);

          tokens[0] = result;
        }
      }
    }
    return tokens[0];

    function multiply(x, y) {
      let d = x * y;
      return Math.round(d * 100 + Number.EPSILON) / 100;
    }

    function add(x, y) {
      return x + y;
    }

    function minus(x, y) {
      return x - y;
    }

    function divison(x, y) {
      let d = x / y;
      return Math.round(d * 100 + Number.EPSILON) / 100;
    }

  }

  toggleValue(item) {
    item.selected = !item.selected;
  }

  onFailScrollToElement(element: HTMLElement) {
    element.scrollIntoView({ behavior: "smooth", block: 'center' });
  }

  //check if item is visible or not; if not post "NA"; if visible && undefined post "Null"; else, is visible post value of question
  answerDefault(question, answer) {
    answer.descriptionValueAnswer = "text";
    answer.questionSynthesis = question.qSynthesis
    if(question.visible==false)
    {
      answer.valueAnswer="NA";
    }
    else if(question.value==undefined && question.visible==true)
    {
      answer.valueAnswer="Null";
    }
    else{
      answer.valueAnswer = question.value;
    }

    return answer;
  }

  answerNumber(question, answer) {
    answer.descriptionValueAnswer = "number";
    answer.questionSynthesis = question.qSynthesis
    if(question.conditionalQuestion && question.conditionalQuestion==true){
      answer.conditionalQuestion=1; //if true it takes the value 1
    }
    if(question.visible==false)
    {
      answer.valueAnswer="NA";
    }
    else if(question.value==undefined && question.visible==true)
    {
      answer.valueAnswer="Null";
    }
    else{
      answer.valueAnswer = question.value;
    }
    return answer;
  }

  answerRadioBox(questionValues, question, answer) {
    answer.descriptionValueAnswer = "radio";
    if(question.conditionalQuestion && question.conditionalQuestion==true){
      answer.conditionalQuestion=1; //if true it takes the value 1
    }
    let _answers:any = [];
    answer.questionSynthesis = question.qSynthesis
    if(question.visible==false)
    {
      answer.valueAnswer="NA";
      _answers.push(answer);
    }
    else if(question.value==undefined && question.visible==true)
    {
      answer.valueAnswer="Null";
      _answers.push(answer);
    }
    else{
      for (let values of questionValues) {
        if (values.value == question.value) {
        // answer.valueAnswer = values.cod;
        answer.valueAnswer = values.value;
          answer.descriptionValueAnswer = values.label;
          _answers.push(answer);
        }
      }
    }
    return _answers;
  }

  answerCheckBox(questionValues, question, answer) {
    answer.descriptionValueAnswer = "check";
    let countSelects=0;
    let _answers:any=[]
    answer.questionSynthesis = question.qSynthesis;
    if(question.visible==false)
    {
      answer.valueAnswer="NA";
    }
    else{
        let iterations = questionValues.length;
        //iterate values to check if item are select
        for (let values of questionValues) {
          if (values.selected == true) {
            countSelects=countSelects+1;
            answer.valueAnswer = (values.value + ";" + answer.valueAnswer);
            answer.descriptionValueAnswer = (values.label + ";" + answer.descriptionValueAnswer);
          }
        }
        //if not have items check
        if (countSelects==0)
        {
          answer.valueAnswer="Null"
        }
        //for remove ";"
        if(answer.valueAnswer.lastIndexOf(";") != -1)
        {

          answer.valueAnswer=answer.valueAnswer.slice(0, -1);
          answer.descriptionValueAnswer=answer.descriptionValueAnswer.slice(0, -1);
        }
    }
    _answers.push(answer);
    return _answers;
  }

  answerMatrix(question,qRows, answer) {
    let _answers:any = [];
   //have to iterate all items because de matrix structure is diferent of the other items
    for (let r of qRows) {
      let _answer = { ...answer }
      _answer.question = r.label;
      _answer.qSynthesis = r.qSynthesis;
      _answer.questionSynthesis = r.qSynthesis;
      _answer.questionNumber = parseInt(r.id);
      if(question.conditionalQuestion && question.conditionalQuestion==true){
        answer.conditionalQuestion=1; //if true it takes the value 1
      }
      if(question.visible==false)
      {
        _answer.valueAnswer = "NA";
        _answer.descriptionValueAnswer = "NA";
      }
      else if (r.valueLine == undefined && question.visible==true) {
        _answer.valueAnswer = "Null";
        _answer.descriptionValueAnswer = "Null";
      }
      else {
        _answer.valueAnswer = r.valueLine.toString();
        _answer.descriptionValueAnswer = r.valueLine.toString();

      }
      _answers.push(_answer);
    }
    return _answers;
  }

  answerSelect(question, answer){
    answer.descriptionValueAnswer = "select";
    answer.questionSynthesis = question.qSynthesis;
    if(question.conditionalQuestion && question.conditionalQuestion==true){
      answer.conditionalQuestion=1; //if true it takes the value 1
    }
    if(question.visible==false)
    {
      answer.valueAnswer="NA";
    }
    else if(question.value==undefined && question.visible==true)
    {
      answer.valueAnswer="Null";
    }
    else{
      answer.valueAnswer = question.value;
      for (let values of question.values) {
        if (values.value == question.value) {
          answer.descriptionValueAnswer = values.label;
        }
      }
    }

    return answer;
  }

  answerDate(question, answer){
    answer.questionSynthesis = question.qSynthesis;
    answer.descriptionValueAnswer = "date"
    if(question.visible==false)
    {
      answer.valueAnswer="NA";
    }
    else if(question.value==null && question.visible==true)
    {
      answer.valueAnswer="Null";
    }
    else{
      const datepipe: DatePipe = new DatePipe('en-US')
      answer.valueAnswer = datepipe.transform(question.value, 'dd-MM-yyyy')
    }
    return answer;
  }

  answerOrderItem(questionValues, question, answer)
  {
    answer.descriptionValueAnswer = "order"
    answer.questionSynthesis = question.qSynthesis;
    if(question.visible==false)
    {
      answer.valueAnswer="NA";
    }
    else{
      for(let v of questionValues)
    {
      answer.valueAnswer=answer.valueAnswer+";"+v.value;
      answer.descriptionValueAnswer=answer.descriptionValueAnswer+";"+v.label;
    }

    answer.valueAnswer=answer.valueAnswer.slice(1);
    answer.descriptionValueAnswer=answer.descriptionValueAnswer.slice(1);
    }

    return answer;
  }

  // check if there are dependent questions don't use in moment, is like a FF solution
  checkDependQuestion(questionsArr, question) {
    // 0 = no dependencie
    // 1 = has dependencie but not found match value
    // 2 = has dependencie and match value
    let code = 0;
    if (question.dependencies) {
      code = 1;
      let f = questionsArr.find(x => x.name == question.depQuestion && x.valueAnswer == question.depValue);
      if (f) {
        question.descriptionValueAnswer = f.descriptionValueAnswer;
        f.freeTextDescription = question.value;
        question.valueAnswer = question.value;
        f.freeTextValue = 1;
        code = 2;
      }
    }
    return code;
  }


  //check if number of answers X are N in sequence way
  limit(){
    this.countNot=0;
    for(let q of this.formData.attributes)
    {
      if(this.countNot>=this.limitNumber)
      {
       break;
      }
      if(q.type=="radio" && q.visible==true){

        if (q.value==this.limitValue)
        {
          this.countNot= this.countNot+1;
        }
        else{
          this.countNot=0;
        }
      }
      else if(q.type=="matrix" && q.visible==true){
        for (let r of q.values[1].rows)
        {
          if (parseInt(r.valueLine)==this.limitValue)
          {
            this.countNot= this.countNot+1;
          }
          else{
            this.countNot=0;
          }
          if(this.countNot>=this.limitNumber)
          {
           break;
          }
        }
      }
    }
    if(this.countNot>=this.limitNumber)
    {
      this.end=true;
      this.btSubmit=false;
      this.submitted = false;
      this.submitForLimit();
    }
  }

   validateEmail(email)
    {
    var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
    if (reg.test(email)){
    return true; }
    else{
    return false;
    }
    }

  receiveResponse(response)
  {
    this.newDepSection=response;
  }
  receiveResponsebtSubmit(response)
  {
    this.btSubmit=response;
  }

  submit() {
    this.btSubmit=false;
    this.submitted = false;
    this.loadfields=false;
    let valid = true;

    // join 2 arrays of responses
    let validationArray;
    if(this.newDepSection==undefined)
    {
       validationArray = this.formData.attributes;
    }
    else{
       validationArray = [...this.formData.attributes, ...this.newDepSection];
    }

    //check validations
    validationArray.forEach(field => {
      let countTrue = 0;
      let countInvalid = 0;

      let countRadio = 0;
      if (field.required && !field.value && field.type != "checkbox" && field.type != "number" && field.type != "matrix" && field.visible==true) {
        Swal.fire({
          icon: 'error',
          title: 'Erro! Por favor complete todas as respostas!',
          text: '' + field.label,
        })
        valid = false;
        return false;
      }
      else if (field.required && field.visible==true && field.type == "checkbox") {
        for (let v of field.values) {
          if (v.selected == true) {
            countTrue = 1;
          }

        }
        if (countTrue == 0) {
          Swal.fire({
            icon: 'error',
            title: 'Erro! Por favor complete todas as respostas!',
            text: '' + field.label,
          })
          valid = false;
          return false;
        }
      }
      else if (field.type == "number" && field.required&& field.visible==true&& ((field.value < JSON.parse(field.min) || field.value > JSON.parse(field.max)) || field.value==null)) {
        Swal.fire({
          icon: 'error',
          title: 'Erro! Por favor complete todas as respostas!',
          text: '' + field.label + '. Valor min: '+field.min+' valor max: '+field.max,
        })
        valid = false;
        return false;
      }
      else if (field.type == "matrix") {
        for (let r of field.values[1].rows) {
          if (r.valueLine != undefined) {
            countRadio = 1;
            //lblRadio = r.label;
          }
          else if(r.required==true && field.visible==true) {
            if (countInvalid == 0) {
              let _this = this;
              let xx = this._elementRef.nativeElement.querySelector("#radio_" + r.id); // to be worked, only works for radios in matrix elements
              Swal.fire({
                icon: 'error',
                title: 'Erro! Por favor complete todas as respostas!',
                text: '' + r.label,
                heightAuto: false
              }).then(function () {
                // scroll to failed question
                setTimeout(() => {
                  _this.onFailScrollToElement(xx);
                }, 500);
              })
              countInvalid++;
            }
            valid = false;
            return false;
          }
        }
      }

      else if(field.type== "email" && field.required && field.visible==true)
      {
        if(!this.validateEmail(field.value))
        {
          Swal.fire({
            icon: 'error',
            title: 'Erro! Email inválido',
            text: '' + field.label,
          })
          valid = false;
          return false;
        }
      }

    });
    if (!valid) {
      this.loadfields=true;
      this.btSubmit=true;
      return false;
    }

    else {

      this.loadfields=true;
      Swal.fire({
        title: "Tem a certeza que pretende submeter o formulário?",
        html: "Após a submissão não poderá alterar as suas respostas.",
        showDenyButton: true,
        showCancelButton: false,
        allowOutsideClick: false,
        confirmButtonColor: '#41BBC9',
        denyButtonColor: '#E5004E',
        confirmButtonText: "Sim",
        denyButtonText: "Não",
        customClass: {
          cancelButton: 'order-1 right-gap',
          confirmButton: 'order-2',
          denyButton: 'order-3',
        }
      }).then((result) => {
        this.loadfields=false;
        if (result.isConfirmed) {
          const datepipe: DatePipe = new DatePipe('en-US')
          let data= formatDate(new Date, 'yyyy-MM-dd', 'en-US')
                // iterate over form questions
                for (let question of validationArray) {

                  // answer object template for each question
                  let answer = {
                    question: question.label, //check
                    questionSynthesis: "", //check
                    valueAnswer: "", //check
                    descriptionValueAnswer: "", //check
                    questionNumber: parseInt(question.id) //check,
                  };

                    // check type of item and call function appropriate for build the answer
                    if (question.type == "text" || question.type == "textarea"|| question.type == "email") {

                      this.fillAnswer.push(this.answerDefault(question, answer));
                    }

                    else if (question.type == "autocomplete"){
                      this.fillAnswer.push(this.answerSelect(question, answer));
                    }
                    else if (question.type == "date"){
                      this.fillAnswer.push(this.answerDate(question, answer));
                    }

                    else if (question.type == "number") {
                      this.fillAnswer.push(this.answerNumber(question, answer));
                    }

                    // if object is type checkbox
                    else if (question.type == "checkbox") {
                      this.fillAnswer.push(...this.answerCheckBox(question.values, question, answer));
                    }
                    // if object is type radio
                    else if (question.type == "radio") {
                      this.fillAnswer.push(...this.answerRadioBox(question.values, question, answer));
                    }
                    // if object is type orderItem
                    else if (question.type == "orderItem") {
                      this.fillAnswer.push(this.answerOrderItem(question.values, question, answer));
                    }
                    // if object is type matrix
                    else if (question.type == "matrix") {
                      this.fillAnswer.push(...this.answerMatrix(question,question.values[1].rows, answer));
                    }
                }

                //check if this.havePontuation is true, if yes calculate scales
                if(this.havePontuation)
                {
                  this.calculatePontuations();
                }

                //build object to post for API
                let userInputFormAnswers={phaseFormId: this.phaseForm.id, answer:this.fillAnswer, feedBacks: this.arrayFeedbacks}
                //console.log("Answers: ",userInputFormAnswers)
                //post request with the object userInputFormAnswers

                this.svApi.addUserPhaseFormAndAnswers(userInputFormAnswers).pipe(first()).subscribe
               (
                 result=>
                 {
                  this.submitted = true;
                  this.loadfields=true;
                  if(!this.haveSubmissionMsg)
                  {
                    Swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: "Formulário submetido com sucesso. Clique em continuar para sair.",
                      allowOutsideClick: false,
                      showConfirmButton:true,
                      timer: 5000,
                      heightAuto: false,
                    });
                  }
                  else{
                    Swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: "Formulário submetido com sucesso. Clique em continuar para sair.",
                      text: this.submissionMsg,
                      allowOutsideClick: false,
                      showConfirmButton:true,

                      heightAuto: false,
                    });
                  }

                 },
                 err=>
                 {
                  this.loadfields=true;
                  Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "Ocorreu um erro, por favor tente novamente.",
                    showConfirmButton: false,
                    timer: 5000,
                    heightAuto: false,
                  });
                  this.router.navigate(["home"]);
                 }
               );







        }
        else{
          this.loadfields=true;
          this.btSubmit=true;
        }
      })

    }

  }

  //submit especific when the form have limit, the message is different
  submitForLimit() {
    this.btSubmit=false;
    this.submitted = false;
    this.loadfields=false;
    // build final object
    let countInvalid = 0;
    let countTrue = 0;
    let countRadio = 0;
    let valid = true;
    let validationArray = this.formData.attributes;

    validationArray.forEach(field => {

      if (field.required && !field.value && field.type != "checkbox" && field.type != "number" && field.type != "matrix") {
        Swal.fire({
          icon: 'error',
          title: 'Erro! Por favor complete todas as respostas!',
          text: '' + field.label,
        })
        valid = false;
        return false;
      }
      else if (field.required && field.type == "checkbox") {
        for (let v of field.values) {
          if (v.selected == true) {
            countTrue = 1;
          }

        }
        if (countTrue == 0) {
          Swal.fire({
            icon: 'error',
            title: 'Erro! Por favor complete todas as respostas!',
            text: '' + field.label,
          })
          valid = false;
          return false;
        }
      }
      else if (field.type == "number" &&  ((field.value < JSON.parse(field.min) || field.value > JSON.parse(field.max)) || field.value==null)) {
        Swal.fire({
          icon: 'error',
          title: 'Erro! Por favor complete todas as respostas!',
          text: '' + field.label,
        })
        valid = false;
        return false;
      }
      else if (field.type == "matrix") {
        for (let r of field.values[1].rows) {
          if (r.valueLine != undefined) {
            countRadio = 1;
            //lblRadio = r.label;
          }
          else if(r.required==true) {
            if (countInvalid == 0) {
              let _this = this;
              let xx = this._elementRef.nativeElement.querySelector("#radio_" + r.id); // to be worked, only works for radios in matrix elements
              Swal.fire({
                icon: 'error',
                title: 'Erro! Por favor complete todas as respostas!',
                text: '' + r.label,
                heightAuto: false
              }).then(function () {
                // scroll to failed question
                setTimeout(() => {
                  _this.onFailScrollToElement(xx);
                }, 500);
              })
              countInvalid++;
            }
            valid = false;
            return false;
          }
        }
      }

      else if(field.type== "email" && field.required )
      {
        if(!this.validateEmail(field.value))
        {
          Swal.fire({
            icon: 'error',
            title: 'Erro! Email inválido',
            text: '' + field.label,
          })
          valid = false;
          return false;
        }
      }

    });
    if (!valid) {
      this.loadfields=true;
      this.btSubmit=true;
      return false;
    }
    else {
          const datepipe: DatePipe = new DatePipe('en-US')
          let data= formatDate(new Date, 'yyyy-MM-dd', 'en-US')

          // iterate over form questions
          for (let question of validationArray)
          {
            // answer object template for each question
            let answer = {
                    question: question.label, //check
                    questionSynthesis: "", //check
                    valueAnswer: "", //check
                    descriptionValueAnswer: "", //check
                    questionNumber: parseInt(question.id) //check,
            };

                  // check for dependencie questions if found transform parent question but dont push the depQuestion to array
                // if (this.checkDependQuestion(this.fillAnswer, question) == 0) { daniel 12/03/2021

                    // if object is type number or text
                    if (question.type == "text" || question.type == "textarea"|| question.type == "email") {
                      this.fillAnswer.push(this.answerDefault(question, answer));
                    }

                    else if (question.type == "autocomplete"){
                      this.fillAnswer.push(this.answerSelect(question, answer));
                    }
                    else if (question.type == "date"){
                      this.fillAnswer.push(this.answerDate(question, answer));
                    }

                    // if object is type number
                    else if (question.type == "number") {
                      this.fillAnswer.push(this.answerNumber(question, answer));
                    }

                    // if object is type checkbox
                    else if (question.type == "checkbox") {
                      this.fillAnswer.push(...this.answerCheckBox(question.values, question, answer));
                    }
                    // if object is type radio
                    else if (question.type == "radio") {
                      this.fillAnswer.push(...this.answerRadioBox(question.values, question, answer));
                    }
                    // if object is type orderItem
                    else if (question.type == "orderItem") {
                      this.fillAnswer.push(this.answerOrderItem(question.values, question, answer));
                    }
                    // if object is type matrix
                    else if (question.type == "matrix") {
                      this.fillAnswer.push(...this.answerMatrix(question, question.values[1].rows, answer));
                    }
                }


                //check if this.havePontuation is true, if yes calculate scales
                if(this.havePontuation)
                {
                  this.calculatePontuations();
                }


                //let userInputFormAnswers={formID: this.formID,userInputID: this.userID, date: data, answers:this.fillAnswer, feedback: this.arrayFeedbacks}
                let userInputFormAnswers={phaseFormId: this.phaseForm.id, answer:this.fillAnswer, feedBacks: this.arrayFeedbacks}
                //inserir post do inputform que criamos
               this.svApi.addUserPhaseFormAndAnswers(userInputFormAnswers).pipe(first()).subscribe
               (
                 data=>
                 {
                  this.submitted = true;
                  this.loadfields=true;
                  var heightPage = document.body.scrollHeight;
                  window.scrollTo(0 , heightPage);
                  if(!this.haveSubmissionMsg)
                  {
                    Swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: "O preenchimento deste questionário terminou automaticamente pela plataforma. Prossiga, por favor, para o próximo questionário. Clique em continuar para sair.",
                      showConfirmButton: true,
                      allowOutsideClick: false,
                      timer: 15000,
                      heightAuto: false,
                    });
                  }
                  else{
                    Swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: this.submissionMsg,
                      allowOutsideClick: false,
                      showConfirmButton:true,
                      timer: 5000,
                      heightAuto: false,
                    });
                  }

                 },
                 err=>
                 {
                  this.loadfields=true;
                  Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "Ocorreu um erro, por favor tente novamente.",
                    showConfirmButton: false,
                    timer: 5000,
                    heightAuto: false,
                  });
                 }
               );




          }

        }

}
