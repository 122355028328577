

<div *ngIf="dataIsNull==true&&loading==false">
  <div class="row justify-content-center" style="margin-right:0px; margin-left: 0px;">
      <h1>Projeto não disponível</h1>
  </div>
</div>

<div *ngIf="dataIsNull==false" id="pdfTable" #pdfTable>
      <div class="row justify-content-center" style="margin-right:0px; margin-left: 0px;">
          <div [innerHtml]="htmlContent" class="col-12 col-md-12 col-lg-12 col-xl-12 titleDiv blueColor"></div>


          <div [hidden]="signedTerm" [ngClass]="{'signedEror': (!ciValidation  || !captcha), 'signedSuccess': (ciValidation  && this.captcha)}" class="col-12 titleDiv" style="margin-top: 3rem;">
              <div class="row">
                  <!-- not signed -->
                  <div class="col">
                    <div style="margin-bottom: 1rem;">
                      <h4 style="padding-bottom: 0;">
                        Declaração de Consentimento Informado (CI)
                      </h4>
                      <hr style="margin-top: 0px; margin-bottom: 2rem; border-top: 1px solid #E2E2E2 !important;">
                    </div>
                    <table style="width: 100%; height:90px; border-collapse:inherit !important;">
                      <tbody>
                      <tr class="" *ngFor="let check of checkList; let i=index;">
                        <td style="width: 20px;">
                          <input type="checkbox" [value]="i"  name="radio1" [(ngModel)]="check.check" required (click)="checkClick(check.check, i)">
                          <!-- <input type="radio" [value]="0" name="radio1" [(ngModel)]="ciValidation1" required> -->
                        </td>
                        <td style="padding-left: 5px; font-weight: normal;">
                        <span style="padding-left: 5px;">{{check.label}}</span>
                        </td>
                      </tr>


                    </tbody>
                    </table>

                    <!-- <div style="margin-top: 10px;">Entendi, foi-me explicado e recebi uma cópia deste documento.</div> -->
                  </div>
                  <!-- signed -->

                </div>
                <div class="row dRecaptcha" style="padding-left: 15px;margin-top: 10px;">
                  <re-captcha
                      [(ngModel)]="captcha"
                      name="captcha"
                      required
                      siteKey="{{reCaptchaKey}}"
                      #captchaControl="ngModel"
                      [required]="true"
                      ></re-captcha>

                </div>

          </div>

          <div [hidden]="!signedTerm" class="col-12 titleDiv signedSuccess" style="margin-top: 3rem;">
              <div class="row">
                  <!-- not signed -->
                  <div class="col">
                    <div style="margin-bottom: 1rem;">
                      <h4 style="padding-bottom: 0;">
                      Declaração de Consentimento Informado (CI)
                      </h4>
                      <hr style="margin-top: 0px; margin-bottom: 2rem; border-top: 1px solid #E2E2E2 !important;">
                    </div>
                    <table style="width: 100%; height:90px; border-collapse:inherit !important;">
                      <tbody>
                      <tr class="" *ngFor="let check of checkList; let i=index;">
                          <td style="width: 20px;vertical-align: baseline;">
                          <input type="checkbox" [value]="i"  name="radio1" checked disabled >
                          <!-- <input type="radio" [value]="0" name="radio1" [(ngModel)]="ciValidation1" required> -->
                        </td>
                        <td style="padding-left: 5px; font-weight: normal;">
                        <span style="padding-left: 5px;">{{check.label}}</span>
                        </td>
                      </tr>


                    </tbody>
                    </table>
                    <!-- <div style="margin-top: 10px;">Entendi, foi-me explicado e recebi uma cópia deste documento.</div> -->
                  </div>
                  <!-- signed -->

                </div>
              <div *ngIf="loadUserDetails" class="col-12" style="text-align: right; margin-top: 1rem;">
                  Consentido em: <b>{{user.dateCiCheck}}</b>
              </div>
          </div>

      </div>
      <!-- <div *ngIf="this.project.downloadCIBool" class="row" style="margin-top: 2rem; text-align: end;">
          <div class="col-12">
             <button [hidden]="!ciValidation || signedTerm || !captcha "style="width: 290px; margin-right:20px;" id="submit" class="btForm" (click)="downloadAsPDFAndGo()"> DOWNLOAD do CI e INICIAR</button>
              <button [hidden]="!ciValidation || signedTerm || !captcha || (this.project.downloadCIBool && !downloadedCi)"style="" id="submit" class="btForm" (click)="submit()"> INICIAR</button>
              <button [hidden]="!signedTerm"style="width: 290px; margin-right:20px;" id="submit" class="btForm" (click)="downloadAsPDF()"> DOWNLOAD CONSENTIMENTO</button>
              <button [hidden]="!signedTerm" class="btForm" routerLink="/home">CONTINUAR</button>
          </div>
      </div> -->
      <div class="row" style="margin-top: 2rem; text-align: end;" >
        <div class="col-12">
           <button [hidden]="!ciValidation || signedTerm || !captcha || !haveFile"style="width: 290px; margin-right:20px;" id="submit" class="btForm" (click)="downloadAsPDF()"> DOWNLOAD Consentimento</button>
            <button [hidden]="!ciValidation || signedTerm || !captcha || (this.requiredDownload && !downloadedCi)"style="" id="submit" class="btForm" (click)="submit()"> INICIAR</button>
            <button [hidden]="!signedTerm ||!haveFile"style="width: 290px; margin-right:20px;" id="submit" class="btForm" (click)="downloadAsPDF()"> DOWNLOAD CONSENTIMENTO</button>
            <button [hidden]="!signedTerm" class="btForm" routerLink="/home">CONTINUAR</button>
        </div>
    </div>
</div>


<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
