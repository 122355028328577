/*
HOME: 	-Logótipo do projeto: caso não tenha nenhum logótipo é apresentado o logótipo do ProChild;
	      -É verificada o intervalo de datas do projeto, caso o projeto não esteja disponível, não aparecem os questionários, aparece a informação de que o projeto não está disponível;
	      -Secção com informações relativas ao projeto (titulo, descrição)
	      -TAB “Instrumentos”: são listados todos os instrumentos em estado publicado pertencentes ao estudo em questão; É verificado se o instrumento já foi preenchido ou não;
	      -TAB “Feedbacks”: fica visível após o preenchimento de uma formulário que contenha feedbacks; Nesta TAB é possível obter informação relativa ao feedback obtido em cada um dos questionários preenchidos. A informação do feedback é constituída pelo nome do instrumento, nome da escala e mensagem obtida;
*/

import { Component,  ElementRef, ViewChild, OnInit } from '@angular/core';
import {ApiService} from '../shared/services/api.service';
import {DataService} from '../shared/services/data.service';
import { Router } from '@angular/router';
import { DatePipe, formatDate } from '@angular/common';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import {AppComponent} from '../app.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
  //providers: [DatePipe]
})
export class HomeComponent implements OnInit {
  public result: any;
  tab1Active:boolean=true;
  tab2Active:boolean=false;
  activeTabFeed:boolean=false;
  feedArray:any=[];
  load:boolean=false;
  projectID:any;
  project:any;
  listForms:any;
  userID:any;
  listFormsUserFill:any;
  todayDate: any;
  projectVisible:boolean=true;
  public listPhaseForms:any;
  public listPhase:any;

  haveResource=false;
  resource:any;
  haveResourcePDF=false;

  public havePhasesActive:boolean=false;

  @ViewChild("scrollDiv", { static: true }) scrollTo: ElementRef;

  constructor(private svApi: ApiService, private activatedRoute: ActivatedRoute,private svData: DataService, private router: Router, private appComponent: AppComponent) {
    this.appComponent.showHeader =true;
  }

  ngOnInit(): void {
    this.load=false;
    this.todayDate=formatDate(new Date(), 'yyyy-MM-dd', 'en-US');

    this.projectID=localStorage.getItem('project');
    this.userID=sessionStorage.getItem('userID');

    //check if project is avaliable
    this.svApi.getProject().subscribe(
      result=>
      {
        this.project=result.data;
        //console.log("project: ", this.project)
        this.project.startDate=formatDate(this.project.startDate, 'yyyy-MM-dd', 'en-US');
        this.project.endDate=formatDate(this.project.endDate, 'yyyy-MM-dd', 'en-US');
        //check if today date is between start and end date
        if(this.project.startDate<=this.todayDate &&this.project.endDate>=this.todayDate &&this.project.state=='Publish')
        {
          this.projectVisible=true;
          this.result = this.activatedRoute.snapshot.data.status.data;
          //console.log("result", this.result)
          if(this.result.unfilled.length<1)
          {
            //console.log("vazio")
            this.havePhasesActive=false;
          }
          // setTimeout(() => {
          //     this.scrollTo.nativeElement.scrollIntoView({behavior: 'smooth'});
          // },500);


         // this.checkIfCanShowFeedback(this.result.filled) 2024/03/12

          if(this.project.haveResources==true)
          {
            this.svApi.getResourceByProjectID(this.project.id).subscribe(
              result=>
              {
                if(result.data.length>0)
                {
                  this.haveResource=true;
                  this.resource=result.data[0];
                  if(this.resource.fileName!=undefined && this.resource.fileName!="")
                  {

                    this.haveResourcePDF=true;

                  }
                  this.load=true;
                }
                else{
                  this.load=true;
                }
              },
              error=>
              {
                Swal.fire({
                  icon: 'error',
                  title: 'Erro! ',
                  text: 'Erro ao obter os recursos'+error,
                })
              }
            )
          }
          else   this.load=true;
        }
        else{
          this.projectVisible=false;
          this.load=true;
        }

      },
      error=>
      {
        Swal.fire({
          icon: 'error',
          title: 'Erro! ',
          text: 'Erro ao obter projeto'+error,
        })
      }
    )

  }



  checkIfUnFilledIsEmpty(filledArr) {
    const results = filledArr.filter(({ id: id1 }) => ! this.result.unfilled.some(({ id: id2 }) => id2 === id1));
    for (let index = 0; index < results.length; index++) {
        const elm = results[index];
        elm.canShowFeedback = true;
    }
  }

  checkIfCanShowFeedback(filledArr) {
      this.checkIfUnFilledIsEmpty(filledArr); // check (and transform) elements of filledArr that belongs ONLY to filled array and are not on unfilled array
      for (let index = 0; index < filledArr.length; index++) { // go throuth filled array pahses
          const filledElm = filledArr[index];
          if (filledElm.canShowFeedback) { // only do this when phase is complete
              for (let index = 0; index < filledElm.form.length; index++) {
                  const formData = filledElm.form[index];
                  formData.canShowFeedback = false; //inside form
                  for (let index = 0; index < formData.feedBacks.length; index++) {
                      const feedBacksElm = formData.feedBacks[index];
                      if (feedBacksElm.showFeedback == true) { //inside feedBacks
                          formData.canShowFeedback = true;
                          break; // already found one no need to continue this feedBacks for cycle
                      }
                  }
              }
          }
      }
  }


  //click in "responder" goes to the specific form
  goForm(formID:any, phaseID:any)
  {
      this.svData.changeForm(formID, phaseID);
      this.router.navigate(['/form']);
  }

  //for download file of resources
  downloadResource()
  {
    this.load=false;
    //console.log(this.resource.id)
    this.svApi.getFileByID(this.resource.id).subscribe(
      result=>{
        let file=result;
        var fileURL = window.URL.createObjectURL(file);
        let tab:any = window.open();
        tab.location.href = fileURL;
        this.load=true;
      },
      error=>{
        //console.log(error)
        Swal.fire({
          icon: 'error',
          title: 'Erro! ',
          text: 'Erro ao obter ficheiro'+error,
        })
      })
  }

  getProjectState() {
    this.svApi.getProject().subscribe(
       result=>{
         let project =result.data;

       },
       error=>{
         return false
       }
     )

   }

}
