import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-form-subtitle',
    templateUrl: './subtitle.component.html',
    providers: []
})

export class SubtitleComponent implements OnInit {

    @Input() itemLabel: any;

    constructor() { }

    ngOnInit() {
    }

}