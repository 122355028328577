import { Component, Input, OnInit, TemplateRef} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
    selector: 'app-form-textarea-child',
    template: `
    <div class="box">
    
    <label class="questionLbl"><i class="fas fa-text-width faIcon yellow paddingIconR"></i> {{item.label}} <label *ngIf="item.required" class="text-danger">*</label>
    </label> 
    <button *ngIf="item.help.length!=0" style="background:none; border:none; color: #233a80;" 
    tooltip="{{item.help}}">
    <i class="far fa-question-circle"></i>
    </button>
    <p>
    <img *ngIf="item.haveImage" [src]="item.ImageBase64" (click)="openImage(template, item.ImageBase64)" class="img-responsive" style="max-width: 300px;max-height: 300px;cursor:zoom-in;">
    </p>
    <textarea oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"' rows=1 class="form-control"
    id="{{item.name}}" 
    [(ngModel)]="item.value"
    [required]="item.required" 
    [ngModelOptions]="{standalone: true}" autocomplete="off"
    maxlength="{{item.max}}"></textarea>
    </div>

    <ng-template #template>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Image</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true" style="font-size: 2rem;
        font-weight: initial;">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="text-align: center;">
        <img [src]="imageModel" style=" max-height: 100%;max-width: 100%;cursor: pointer; " >
    </div>
    <div class="modal-footer">

    </div>
   
  </ng-template>
    `,
    styleUrls: ['./textarea.component.scss'],
    providers: []
})

export class TextareaChildComponent implements OnInit {

    
    @Input() itemValue: any;
    @Input() itemRequired: any;
    @Input() itemName: any;
    @Input() itemLabel: any;
    @Input() item: any;
    @Input() itemMax: any;
    @Input() itemPlaceholder: any;
    template: TemplateRef<any>;
    modalRef: BsModalRef;
    imageModel:any;

    constructor(private modalService: BsModalService) { }

    ngOnInit() {

    }

    openImage(template: TemplateRef<any>, image) 
    {
        this.imageModel=image;
            this.modalRef = this.modalService.show(
              template
            );
    }

}