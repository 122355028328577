import { Component,Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-paragraph',
  templateUrl: './paragraph.component.html',
  styleUrls: ['./paragraph.component.scss']
})
export class ParagraphComponent implements OnInit {
  @Input() itemLabel: any;
  @Input() item: any; 
  constructor() { }

  ngOnInit(): void {
  }

}
