
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import {  RouterModule } from '@angular/router';

import {  ErrorInterceptor } from './shared/services/error.interceptor';
import { HttpClientModule,HTTP_INTERCEPTORS} from "@angular/common/http";
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ReactiveFormsModule } from '@angular/forms';
//import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';

import { 
  EmailChildComponent, 
  EmailComponent, 
  CheckboxComponent,
  FormElementComponent, 
  MatrixComponent, 
  NumberChildComponent, 
  NumberComponent, 
  RadioChildComponent, 
  RadioComponent, 
  SubtitleComponent, 
  TextareaChildComponent, 
  TextareaComponent, 
  TextChildComponent, 
  TextComponent, 
  TitleComponent, 
  QuestiontitleComponent,
  SelectComponent,
  DateComponent,
  LinkComponent} from './shared/elements';
  import { FormComponent } from './forms/form.component';
  import { FormBuilderComponent } from './forms/form-builder.component';
import { CiComponent } from './ci/ci.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { OrderItensComponent } from './shared/elements/order-itens/order-itens.component';
import { ParagraphComponent } from './shared/elements/paragraph/paragraph.component';
import { NgxLoadingModule } from 'ngx-loading';
import { ModalModule } from 'ngx-bootstrap/modal';
import { environment } from '../environments/environment';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';

import { TabsModule } from 'ngx-bootstrap/tabs';
import { FormBuilderDepSectionComponent } from './forms/depSection/form-builder-dep-section/form-builder-dep-section.component';
import { LoginComponent } from './auth/login/login.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/fontawesome-free';
import { RecoverComponent } from './auth/recover/recover.component';
import { SignupComponent } from './auth/signup/signup.component';
import { CiWithLoginComponent } from './ci-with-login/ci-with-login.component';
import { RecoverPWComponent } from './auth/recover-pw/recover-pw.component';
import { httpInterceptorProviders } from './shared/http-interceptors';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FormComponent,
    EmailChildComponent, 
  EmailComponent, 
  CheckboxComponent,
  FormElementComponent, 
  MatrixComponent, 
  NumberChildComponent, 
  NumberComponent, 
  RadioChildComponent, 
  RadioComponent, 
  SubtitleComponent, 
  TextareaChildComponent, 
  TextareaComponent, 
  TextChildComponent, 
  TextComponent, 
  TitleComponent, 
  QuestiontitleComponent,
  SelectComponent,
  DateComponent,
  FormComponent,
  FormBuilderComponent,
  CiComponent,
  OrderItensComponent,
  ParagraphComponent,
  FormBuilderDepSectionComponent,
  LinkComponent,
  LoginComponent,
  RecoverComponent,
  SignupComponent,
  CiWithLoginComponent,
  RecoverPWComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    RecaptchaModule,  
    RecaptchaFormsModule,
    RouterModule.forRoot([]),
    TooltipModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot()
    
  ],
  providers: [
    httpInterceptorProviders
  ],
  exports: [RouterModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
