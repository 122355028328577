/*
CI-auth- consentimento informado (quando o user necessita de autenticação)
Funcionalidades: responsavel por contruir a página CI
-utiliza o innerHtml para construir baseado em variavel html;
-constrói listagem de checkbox para validação + a validação recaptcha da google
-possibilita o donwload de ficheiro pdf relativo ao CI anexado ao projeto
-após submissão da validação do CI é criado um novo user e guardado em váriavel de sessão (userID)
-criada variavel de sessão (signedTerm) para verficar se o CI já foi validado
*/

import { Component, OnInit, ElementRef, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { Router } from '@angular/router';
import {ApiService} from '../shared/services/api.service';
import {DataService} from '../shared/services/data.service';
import { first } from 'rxjs/operators';
import {environment} from '../../environments/environment';
import Swal from 'sweetalert2';
import {AppComponent} from '../app.component';

@Component({
  selector: 'app-ci-with-login',
  templateUrl: './ci-with-login.component.html',
  styleUrls: ['../ci/ci.component.scss']
})
export class CiWithLoginComponent implements OnInit {

  public loading=false;
  CiID:any;
  dataIsNull:boolean=true;
  htmlContent:any;
  checkList:any;
  public ciValidation: any;
  projectId:any;
  requiredDownload:boolean=false;
  ci:any;
  loadUserDetails: boolean=false;
  signedTerm:boolean=false;
  user:any;
  reCaptchaKey:string;
  downloadedCi:boolean= false; //for check if downloaded CI
  private codeToken={token:''};


  constructor( private svApi: ApiService, private svData: DataService,private route: Router, private appComponent: AppComponent) {
    this.appComponent.showHeader =true;
    this.loading=true;
    this.projectId= localStorage.getItem('project')
    this.reCaptchaKey=environment.RecaptchaV2;
    this.codeToken.token=environment.codeToken;
    //check if CI already Check, if yes - get user info, if not- get CI in empty view
    if (sessionStorage.getItem("signedTerm")=='true')
    {
      //console.log("if")
      this.signedTerm=true;
      // if signedTerm,  then get userConfig details (for put the value in date signedTerm)
      this.svApi.getUser().subscribe(
        data=>{
          this.user=data;
          this.loadUserDetails=true;
        },
        error=>
        {
          //console.log("error get user")
          Swal.fire({
            icon: 'error',
            title: 'Erro! ',
            text: 'get User Input By ID' + error,
          })
        }
      )
      this.loading=false;
    }
    else{
      //console.log("elese")
      //console.log("this.projectId: ", this.projectId)
      this.svApi.getCiByProjectID(this.projectId).subscribe(
        result=>{
          //console.log(result);
          if(result.data.resources.length>0)
          {
            this.ci=result.data.resources[0];
            this.checkList=JSON.parse(this.ci.checkList);
            this.htmlContent=this.ci.html;
            this.requiredDownload=result.data.requiredDownload;
            this.dataIsNull=false;
            this.loading=false;

          }
          else{

            this.dataIsNull=true;
            this.loading=false;
          }



        },
        error=>{
          Swal.fire({
            icon: 'error',
            title: 'Erro! ',
            text: 'Erro no acesso ao CI.' + error,
          })
        }
      )
    }
    if (sessionStorage.getItem("downloadedCi"))
    {
      this.downloadedCi=true;
    }
  }

  ngOnInit(): void {

  }

  //function for donwload CI file or show msg "Não tem nenhum documento anexado"
  public downloadAsPDF() {
    this.loading=true;
    this.svApi.getCiFile(this.projectId,this.ci.id).subscribe(
      result=>{
        /* if want open in new tab
        var fileURL = window.URL.createObjectURL(file);
        let tab:any = window.open();
        tab.location.href = fileURL;
        */
        var newBlob = new Blob([result], { type: "application/pdf" });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }
            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download = this.ci.fileName;
            // this is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            sessionStorage.setItem("downloadedCi", "true")
            this.downloadedCi=true;
        this.loading=false;
      },
      error=>{
        //console.log(error)
        Swal.fire({
          icon: 'error',
          title: 'Erro! ',
          text: 'Erro ao obter ficheiro'+error,
        })
      })
  }

  //function for donwload CI file or show msg "Não tem nenhum documento anexado"
  public downloadAsPDFAndGo() {
    this.loading=true;
    this.svApi.getDocumentByCI(this.CiID).subscribe(
      data=>
      {
        let x:any=data;
        if(x!=null){
          let name=x.name;
          this.svApi.getPDFByDocumentID(x.documentID).subscribe(
            x=>{

            var newBlob = new Blob([x], { type: "application/pdf" });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob);
                    return;
                }
                // For other browsers:
                // Create a link pointing to the ObjectURL containing the blob.
                const data = window.URL.createObjectURL(newBlob);
                var link = document.createElement('a');
                link.href = data;
                link.download = name;
                // this is necessary as link.click() does not work on the latest firefox
                link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
                sessionStorage.setItem("downloadedCi", "true")
                this.downloadedCi=true;
                this.submit();
            });
            this.loading=false;
        }
        else{
          this.loading=false;
          Swal.fire({
            icon: 'info',
            title: 'Não tem nenhum documento anexado! ',
            text: ''
          })
        }
      },
      error=>{
        this.loading=false;
        Swal.fire({
          icon: 'error',
          title: 'Erro! ',
          text: '' + error,
        })
      }
    );
  }

//verify if any value is false
  haveFalse(element, index, array) {
    return element ==false;
  }

//verify if any value is undefined
  haveUndefined(element, index, array) {
    return element ==undefined;
  }


//each click checks any false or undefined value
  checkClick(value, i){
    let list = this.checkList;
    list[i].check=!value;
    let array:any=[]
    for(let check of this.checkList)
    {
      array.push(check.check)
    }
    if(array.some(this.haveFalse)==false && array.some(this.haveUndefined)==false)
    {
      this.ciValidation=true;
    }
    else{
      this.ciValidation=false;
    }

  }

  //submit CI validation, create a new user
  submit(){
    this.loading=true;
    //If submit ci -> create a new user, save the id of new user in sessionStorage and go home
    this.svApi.addSignedTerm().pipe(first()).subscribe(
      result=> {
        //console.log(result)
        let newUser:any=result;
        // localStorage.setItem('project', this.projectId);
         sessionStorage.setItem("userID", newUser.userId)
         sessionStorage.setItem("signedTerm", "true")
         sessionStorage.setItem("access_token", newUser.token)
         this.loading=false;
         this.route.navigate(['home'])
      },
      error=>
      {
        this.loading=false;
        Swal.fire({
          icon: 'error',
          title: 'Erro! ',
          text: 'Erro a atualizar CI' + error,
        })
      }
    );

  }
}
