import { Injectable } from '@angular/core';
import { DefaultResponse } from "../models/default-response.model";
import { environment } from "../../../environments/environment";
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }


//API/Public
  //Project
  
  public checkProjectRequireAuthByID(id):Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormViewPublic + "Project/"+id+"/RequireAuthentication");
  } 
  public getThemeByProjectID(id): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormViewPublic + "Project/"+id+"/Theme");
  }
  public addUser(id,newUser: any): Observable<DefaultResponse<any>> {
    return this.http.post<DefaultResponse<any>>(environment.ApiFormViewPublic + "Project/"+id+"/Register", newUser);
  }
  public getCiByProjectID(id): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormViewPublic + "Project/"+id+"/CI");
  }
  public getCiFile(projectId, ciId): Observable<any>{
    return this.http.get(environment.ApiFormViewPublic+"Project/"+projectId+"/CI/"+ciId+"/File",{ responseType: 'blob' });
  }
  public checkemail(email): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormViewPublic + "User/CheckEmail?email="+email);
  }
  public resetPwSendToken(email): Observable<DefaultResponse<any>>{
    return this.http.post<DefaultResponse<any>>(environment.ApiFormViewPublic + "User/ResetPasswordSendToken",email);
  }
  public resetPw(updateUser): Observable<DefaultResponse<any>>{
    return this.http.post<DefaultResponse<any>>(environment.ApiFormViewPublic + "User/ResetPassword",updateUser);
  }

//API/Private
  //Project
  public getProject(): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormViewPrivate + "Project");
  }
  public getUserStatus(): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormViewPrivate + "Project/UserStatus");
  }
  public getSignedTerm(): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormViewPrivate + "Project/SignedTerm/Status");
  }
  public addSignedTerm(): Observable<DefaultResponse<any>> {
    return this.http.post<DefaultResponse<any>>(environment.ApiFormViewPrivate + "Project/SignedTerm",null);
  }

  public getPhaseByProjectID(id): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormViewPrivate + "Project/"+id+"/Phase");
  }
  //post
  public addUserPhaseFormAndAnswers(userPhaseFormAnswer: any): Observable<DefaultResponse<any>> {
    return this.http.post<DefaultResponse<any>>(environment.ApiFormViewPrivate + "Project/FillForm", userPhaseFormAnswer);
  }


  //Phase
  public getFormsByPhaseID(id): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormViewPrivate + "Phase/"+id+"/Form?state=1");
  }    

  //User
  public getUser(): Observable<DefaultResponse<any>> {
    return this.http.get<DefaultResponse<any>>(environment.ApiFormViewPrivate + "User");
  }


  //ProjectResources

  public getResourceByProjectID(id): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormViewPrivate + "Project/Resource?type=Attachment");
  }
  public getFileByID(id): Observable<any>{
    return this.http.get(environment.ApiFormViewPrivate+"ProjectResource/"+id+"/File",{ responseType: 'blob' });
  }

  //Forms
  
  public getFormByID(id): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormViewPrivate + "Form/"+id);
  }

  public getJsonByID(id): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormViewPrivate + "Form/"+id+"/Json");
  }

  //phaseForm
  public getPhaseFormByID(id): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormViewPrivate + "PhaseForm/"+id);
  }
//------------------------------------------------------------------------------------------------------------------------------------------------------


  public getFormByProjectID(projectId): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormViewPrivate + "Form/listFormsPublishedByProject/"+projectId);
  }

  public getCIProjectID(projectId): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormViewPrivate + "Form/ciByProject/"+projectId);
  }




  //answers

  public addAnswers(answer: any): Observable<DefaultResponse<any>> {
    return this.http.post<DefaultResponse<any>>(environment.ApiFormViewPrivate + "Answer/List", answer);
  }

  //userInputForm
  public getUserInputForm(): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormViewPrivate + "UserInputForm");
  }
  public addUserInputForm(userInputForm: any): Observable<DefaultResponse<any>> {
    return this.http.post<DefaultResponse<any>>(environment.ApiFormViewPrivate + "UserInputForm", userInputForm);
  }
 
  public getUserInputFormByUserID(userID): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormViewPrivate + "UserInputForm/ByUserInput/"+userID);
  }


   //new 2022-01-13 Post de userinputform and answer List
   public addUserInputFormAndAnswers(userInputFormAnswer: any): Observable<DefaultResponse<any>> {
    return this.http.post<DefaultResponse<any>>(environment.ApiFormViewPrivate + "UserInputForm/withAnswers", userInputFormAnswer);
  }
  

  //userInput
  public addUserInput(userInput: any): Observable<DefaultResponse<any>> {
    return this.http.post<DefaultResponse<any>>(environment.ApiFormViewPrivate + "userInput", userInput);
  }
 

   //ci

  //  public getCIByProject(projectID): Observable<DefaultResponse<any>>{
  //   return this.http.get<DefaultResponse<any>>(environment.ApiFormViewPrivate + "CI/ProjectCI/"+projectID);
  // }


   //Document
   public getDocumentByCI(CiID): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormViewPrivate + "Document/DocumentbyCiID/"+CiID);
  }

  public getPDFByDocumentID(DocumentID): Observable<Blob>{
    return this.http.get(environment.ApiFormViewPrivate + "Document/PDFbyDocumentID/"+DocumentID, { responseType: 'blob' });

  }

  //image
  public getImageByID(id): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormViewPrivate + "Image/ImageByID/"+id);
  }


  //feedbacks
  public getFeedbackByUserId(id): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormViewPrivate + "FeedBacks/GetByUserID/"+id);
  }

  //userConfig
  public getUserConfigByUserIDAndFormID(userID,formID,qSynthesis): Observable<DefaultResponse<any>>{
    return this.http.get<DefaultResponse<any>>(environment.ApiFormViewPrivate + "UserConfig/"+userID+"/"+formID+"/"+qSynthesis);
  }

   //resources

  public downloadPDF(id): Observable<Blob>{
    return this.http.get(environment.ApiFormViewPrivate + "ProjectResource/PDFbyResourceID/"+id, { responseType: 'blob' });

  }
}
