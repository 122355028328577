<!-- ITERATE OVER ALL QUESTIONS -->

<div *ngFor="let item of itemQuestions">

  <!-- IF ITEM HAS DEPENDENCIES -->
  <div *ngIf="item.dependencies">

    <!-- CHECK ARRAY AGAIN FOR DEPENDENCIE FLAG -->
    <div *ngFor="let question of itemQuestions">
      <!-- DEPENDENCIE FLAG FOUND -->
      <div *ngIf="item.depQuetion==question.name">
        <!-- SHOW/HIDE IF VALUE EQUALS TO QUESTION VALUE -->
     
        <div *ngIf="item.typeDependencies=='numValue' || item.typeDependencies=='textValue' ||item.typeDependencies==null">
          <!-- <div [hidden]="question.value!=item.depValue" > -->
            <div *ngIf="question.value==item.depValue" >
              <div *ngIf="putVisibleTrue(item)"></div>
            <!-- CALL FORM ELEMENT COMPONENT PASSING TYPE -->
            <!--for change de state of visible <label *ngIf="question.value==item.depValue"> {{changeState(item)}}</label>  -->
            <app-form-element [ngClass]="{'formInputBox': question.qSynthesis || question.type == 'questionTitle' || question.type == 'matrix'}" [item]="item" [itemType]="item.type" [submitted]="submitted" ></app-form-element>
          </div>
          <div *ngIf="question.value!=item.depValue" >
            <div *ngIf="putVisibleFalse(item)"></div>
            </div>
            <!--Checkbox only work "==", because is possible to select many options-->
          <div *ngIf="question.type=='checkbox'">
            <div *ngFor="let value of question.values">
               <div *ngIf="value.selected==true&& value.value==item.depValue">
                <div *ngIf="putVisibleTrue(item)"></div>
                <app-form-element [ngClass]="{'formInputBox': question.qSynthesis || question.type == 'questionTitle' || question.type == 'matrix'}" [item]="item" [itemType]="item.type" [submitted]="submitted"></app-form-element>
  
              </div> 
            </div>
           
          </div>   
        </div>
        <div *ngIf="item.typeDependencies=='less'">
            <!--11/01/2022 <div [hidden]="question.value>=item.depValue || question.value==null"> --> 
              <div *ngIf="question.value<item.depValue">

                <div *ngIf="putVisibleTrue(item)"></div>
              <!-- CALL FORM ELEMENT COMPONENT PASSING TYPE -->
              <!--for change de state of visible <label *ngIf="question.value==item.depValue"> {{changeState(item)}}</label>  -->
              <app-form-element [ngClass]="{'formInputBox': question.qSynthesis || question.type == 'questionTitle' || question.type == 'matrix'}" [item]="item" [itemType]="item.type" [submitted]="submitted" ></app-form-element>
            </div>
            <div *ngIf="question.value>=item.depValue" >
              <div *ngIf="putVisibleFalse(item)"></div>
              </div>
         
        </div>
        <div *ngIf="item.typeDependencies=='higher'">
          <!--11/01/2022 <div [hidden]="question.value<=item.depValue || question.value==null"> -->
            <div *ngIf="question.value>item.depValue">
              <div *ngIf="putVisibleTrue(item)"></div>
            <!-- CALL FORM ELEMENT COMPONENT PASSING TYPE -->
            <!--for change de state of visible <label *ngIf="question.value==item.depValue"> {{changeState(item)}}</label>  -->
            <app-form-element [ngClass]="{'formInputBox': question.qSynthesis || question.type == 'questionTitle' || question.type == 'matrix'}" [item]="item" [itemType]="item.type" [submitted]="submitted" ></app-form-element>
          </div>
          <div *ngIf="question.value<=item.depValue" >
            <div *ngIf="putVisibleFalse(item)"></div>
            </div>
     
        </div>
        <div *ngIf="item.typeDependencies=='between'">
          <!-- 11/01/2022 <div [hidden]="question.value==null|| question.value>=item.depValue2 || question.value<=item.depValue "> -->
            <div *ngIf="question.value<item.depValue2 && question.value>item.depValue">
              <div *ngIf="putVisibleTrue(item)"></div>
              <!-- CALL FORM ELEMENT COMPONENT PASSING TYPE -->
              <!--for change de state of visible <label *ngIf="question.value==item.depValue"> {{changeState(item)}}</label>  -->
              <app-form-element [ngClass]="{'formInputBox': question.qSynthesis || question.type == 'questionTitle' || question.type == 'matrix'}" [item]="item" [itemType]="item.type" [submitted]="submitted" ></app-form-element>
            </div>
            <div *ngIf="!(question.value<item.depValue2 && question.value>item.depValue)" >
              <div *ngIf="putVisibleFalse(item)"></div>
              </div>
        </div>
     
      </div>
      <div *ngIf="question.type=='matrix'">
        <div *ngFor="let r of question.values[1].rows">
          <div *ngIf="item.depQuetion==r.name">
            <div *ngIf="item.typeDependencies=='numValue' || item.typeDependencies=='textValue' ||item.typeDependencies==null">
                <div *ngIf="r.valueLine==item.depValue">
                  <div *ngIf="putVisibleTrue(item)"></div>
                  <app-form-element [ngClass]="{'formInputBox': question.qSynthesis || question.type == 'questionTitle' || question.type == 'matrix'}" [item]="item" [itemType]="item.type" [submitted]="submitted"></app-form-element>           
                </div>
                <div *ngIf="r.valueLine!=item.depValue" >
                  <div *ngIf="putVisibleFalse(item)"></div>
                  </div>
            </div>
            <div *ngIf="item.typeDependencies=='less'">
              <div *ngIf="r.valueLine<item.depValue && r.valueLine!=null">
                <div *ngIf="putVisibleTrue(item)"></div>      
                <app-form-element [ngClass]="{'formInputBox': question.qSynthesis || question.type == 'questionTitle' || question.type == 'matrix'}" [item]="item" [itemType]="item.type" [submitted]="submitted"></app-form-element>           
              </div>
              <div *ngIf="!r.valueLine<item.depValue && !r.valueLine!=null" >
                <div *ngIf="putVisibleFalse(item)"></div>
                </div>
            </div>
            <div *ngIf="item.typeDependencies=='higher'">
              <div *ngIf="r.valueLine>item.depValue && r.valueLine!=null">  
                <div *ngIf="putVisibleTrue(item)"></div>    
                <app-form-element [ngClass]="{'formInputBox': question.qSynthesis || question.type == 'questionTitle' || question.type == 'matrix'}" [item]="item" [itemType]="item.type" [submitted]="submitted"></app-form-element>           
              </div>
              <div *ngIf="!r.valueLine>item.depValue && !r.valueLine!=null" >
                <div *ngIf="putVisibleFalse(item)"></div>
                </div>
            </div>
            <div *ngIf="item.typeDependencies=='between'">
              <div *ngIf="r.valueLine<item.depValue2 && r.valueLine>item.depValue && r.valueLine!=null">    
                <div *ngIf="putVisibleTrue(item)"></div>  
                <app-form-element [ngClass]="{'formInputBox': question.qSynthesis || question.type == 'questionTitle' || question.type == 'matrix'}" [item]="item" [itemType]="item.type" [submitted]="submitted"></app-form-element>           
              </div>
              <div *ngIf="!r.valueLine<item.depValue2 && !r.valueLine>item.depValue" >
                <div *ngIf="putVisibleFalse(item)"></div>
                </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
  <!-- IF ITEM HAS NOT DEPENDENCIES -->
  <div *ngIf="!item.dependencies">
    <!-- CALL FORM ELEMENT COMPONENT PASSING TYPE -->
    <app-form-element [ngClass]="{'formInputBox': item.qSynthesis || item.type == 'questionTitle' || item.type == 'matrix'}" [item]="item" [itemType]="item.type" [submitted]="submitted"></app-form-element>
  </div>
</div>