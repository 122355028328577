<div #scrollDiv class="body container noPadding" *ngIf="load">
  <div class="row justify-content-center blueColor">
    <div class="col-12 titleDiv">
      <div class="row">
          <div class="col cTitle ">
            {{project.name}}
          </div>
      </div>
      <div class="row">
        <div class="col cDesc">
          {{project.description}}
        </div>
      </div>
      <div class="row rResource" *ngIf="haveResource">
        <div class="col-12">
          <div class="row resourcesTitle">
            <h6>Lista de recursos:</h6>
          </div>
          <div class="row">
            <textarea  class="autosize" name="" id="" rows="3"  [(ngModel)]="resource.html" disabled></textarea>
          </div>
          <div class="row mt-4" *ngIf="haveResourcePDF">
            <button class="btForm" (click)="downloadResource()" style="width: 232px;">Download Recursos</button>
          </div>

        </div>

      </div>
    </div>

  </div>

  <div *ngIf="!projectVisible">
    <div class="row justify-content-center" style="margin-right:0px; margin-left: 0px; margin-top:2rem; text-align: center;">
        <h1>Projeto não disponível</h1>
    </div>
  </div>

  <!-- tabs -->
  <div class="row" *ngIf="projectVisible">

    <div class="col-12 noPadding ">

      <div class="row" style="margin-top:2rem;">

        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li [hidden]="result.unfilled.length<=0" class="nav-item" role="presentation">
            <a class="nav-link active" id="active-forms-tab" data-bs-toggle="pill" href="#active-forms" role="tab"
              aria-controls="active-forms" aria-selected="false">ATIVOS</a>
          </li>
          <li [hidden]="result.filled.length<=0" class="nav-item" role="presentation">
            <a class="nav-link" [ngClass]="{'active': result.unfilled.length==0}" id="completed-forms-tab"
              data-bs-toggle="pill" href="#completed-forms" role="tab" aria-controls="completed-forms"
              aria-selected="true">COMPLETOS</a>
          </li>
          <!-- <li class="nav-item" role="presentation">
        <a class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" href="#pills-contact" role="tab"
          aria-controls="pills-contact" aria-selected="false">Contact</a>
      </li> -->
        </ul>
      </div>
      <div class="tab-content titleDiv" id="pills-tabContent">

        <div *ngIf="result.unfilled.length<=0&&result.filled.length<=0"class="row justify-content-center" style="margin-right:0px; margin-left: 0px; margin-top:2rem; text-align: center;">
          <h2>Nenhuma fase disponível de momento</h2>
      </div>
        <!-- tab active forms -->
        <div [hidden]="result.unfilled.length<=0" class="tab-pane fade active show" id="active-forms" role="tabpanel"
          aria-labelledby="active-forms-tab">
          <div class="row">
            <div class="ftheme">
              <div class="col-12 noPadding">

                <!-- phases un filled -->
                <div>

                  <!-- body -->
                  <div class="row justify-content-center" *ngFor="let phase of result.unfilled">

                    <div class="col-xs-12 col-12 col-md-12 col-xl-12 formInputBox">
                      <div class="row" style="padding:0.5rem;">
                        <div class="col">
                          <h4>  <i style="font-size: 30px; margin-right: 5px;" class="far fa-calendar-minus iconyellow"></i>

                            Fase "{{phase.name}}" aberta de
                              {{phase.startDate | date:'dd-MM-yyyy'}}
                              até
                              {{phase.endDate | date:'dd-MM-yyyy'}} </h4>
                        </div>
                      </div>
                      <div class="row" style="padding:0.5rem;">
                        <div class="col">
                          <table class="table table-striped">
                            <tbody>
                              <tr *ngFor="let form of phase.form">
                                <td class="formRow" style="vertical-align: middle; ">
                                  <i style="font-size: 33px; font-weight: 600" class="bi bi-file-text iconyellow"></i>
                                  <span class="table-row-form blueColor">{{form.name}} </span>
                                </td>
                                <td style="text-align:right;vertical-align: middle;">

                                  <!-- if locked   -->
                                  <button class="btForm" [routerLink]="['/form',form.phaseFormId]"
                                    [disabled]="form.isLocked">
                                    <i *ngIf="form.isLocked" class="bi bi-lock-fill"
                                      style="vertical-align: 0.1em; font-size: 17px;"></i>
                                    <i style="font-style: normal;">Responder</i>
                                  </button>


                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- tab completed forms -->
        <div [hidden]="result.filled.length<=0" class="tab-pane fade" [ngClass]="{'active show': result.unfilled.length==0}"
          id="completed-forms" role="tabpanel" aria-labelledby="completed-forms-tab">
          <div class="row">
            <div class="ftheme">
              <div class="col-12 noPadding">
                <!-- filled -->
                <div>
                  <h4>  <i style="font-size: 30px; margin-right: 5px;" class="far fa-comment-dots iconyellow"></i>
                    FeedBack:
                  </h4>
                  <div class="row justify-content-center" *ngFor="let phase of result.filled; let i=index;">

                    <div class="col-xs-12 col-12 col-md-12 col-xl-12 formInputBox">
                      <div class="row rFeedBackForm" style="padding:0.5rem;">
                        <div class="col">
                          <h6 class="colorText"> <i style="font-size: 30px; margin-right: 5px;" class="far fa-calendar-minus iconyellow "></i>
                            Fase "{{phase.name}}" aberta de
                              {{phase.startDate | date:'dd-MM-yyyy'}}
                              até
                              {{phase.endDate | date:'dd-MM-yyyy'}} </h6>
                        </div>
                      </div>
                      <!-- <h5 style="margin-bottom: 15px;margin-top: 15px;">{{form.name}} ({{form.description}})</h5> -->
                      <div class="row" style="">
                        <div class="col">
                          <table class="table  myTable">
                            <tbody>
                              <tr *ngFor="let form of phase.form">
                                <div
                                  style="display: table; width: 100%;">
                              <tr class="">
                                <td class="" style="vertical-align: middle;border:none !important;">
                                  <i style="font-size: 33px; font-weight: 600" class="bi bi-file-text iconyellow"></i>
                                  <span class="table-row-form colorText" style="font-weight: bold;">{{form.name}}</span>
                                </td>
                                <td style="text-align:right;vertical-align: middle;border:none !important;">
                                  <span class="btnFormDone cyan">
                                    Submetido {{form.filledDate | date:'dd-MM-yyyy' }}
                                  </span>
                                </td>
                              </tr>
                        </div>
                        <!-- <div *ngIf="form.canShowFeedback && result.canShowFeedback && result.feedback.length>0" -->
                          <div *ngIf="form.feedBacks.length>0&&form.showFormExpression=='true'"
                          style="background: #ffffff !important; display:table; padding-left: 3.52rem; padding-top: 0px;">
                          <tr *ngFor="let feedback of form.feedBacks; let i=index;">
                            <p class="bluep " style="margin-bottom:0px;font-weight: bold;">{{feedback.scaleNameToUser}}</p>
                            <div class="colorText" style="margin-bottom: 1rem; font-weight: 300; font-size: 18px; font-style: italic; ">{{feedback.scaleMessage}}</div>
                          </tr>
                        </div>
                        </tr>
                        </tbody>
                        </table>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        </div>
    </div>

  </div>

</div>


</div>

<ngx-loading [show]="!load" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

