import { Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-form-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  providers: []
})
export class LinkComponent implements OnInit {

  @Input() item: any;

  constructor() { }

  ngOnInit() {
  }

}
