import { Component, OnInit } from '@angular/core';
import {AppComponent} from '../../app.component';
import {ApiService} from '../../shared/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-recover-pw',
  templateUrl: './recover-pw.component.html',
  styleUrls: ['../login/login.component.scss']
})
export class RecoverPWComponent implements OnInit {
  signUpForm: FormGroup;
  projectIcon:any;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
  public passwordNotMatch: boolean = false;
  submitted = false;
  loading = false;
  private code:any;

  constructor( private svApi: ApiService,
    private appComponent: AppComponent,
    private formBuilder: FormBuilder,
    private route: Router,
    private activeRoute: ActivatedRoute) {
      this.projectIcon=sessionStorage.getItem('projectIcon');
      this.appComponent.showHeader =false;
    }

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe(
      params => {
        this.code=params.code;
        //console.log(this.code)
      },
      error=>{
        Swal.fire({
          icon: 'error',
          title: 'Erro! ',
          text: 'Erro na identificação do Utilizador',
        })
      }
    )
    this.appComponent.showHeader =false;
    this.signUpForm = this.formBuilder.group({
      username: ['', Validators.compose([Validators.required, Validators.pattern(this.emailPattern)])],
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"))]],
      password2: ['', [Validators.required, Validators.minLength(8)]]
  });
  }

  // convenience getter for easy access to form fields
  get f() { return this.signUpForm.controls; }

  onSubmit() {
    //console.log("save")
    this.passwordNotMatch = false
    this.loading = true;
    this.submitted = true;
    let updateUser={email:this.signUpForm.value.username,password:this.signUpForm.value.password,confirmPassword:this.signUpForm.value.password2, code:this.code}
    //console.log(updateUser)

    //console.log(this.signUpForm)
    if (this.f.password.value != this.f.password2.value) {
      this.passwordNotMatch = true;
      this.loading=false;
      return
    }
    if (this.signUpForm.valid) {
      //console.log("valid")
      this.svApi.resetPw(updateUser).subscribe(
        result=>
        {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: "Dados atualizados com sucesso!",
            allowOutsideClick: false,
            showConfirmButton:true,
            timer: 5000,
            heightAuto: false,
          });
        },
        error=>{
          Swal.fire({
            icon: 'error',
            title: 'Erro! ',
            text: 'Erro na atualização da password!',
          })
        }
      )
    }

  }

}
