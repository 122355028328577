import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {AccountService} from './authentication.service';
import {ApiService} from '../../shared/services/api.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  private signedTerm=sessionStorage.getItem('signedTerm');

  constructor(
    private router: Router,
    private accountService: AccountService,
    private svApi: ApiService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    //console.log("canActive: ", this.accountService.isUserLoggedIn())
    //console.log("signedTerm: ",this.signedTerm )
    if (this.accountService.isUserLoggedIn()&&this.signedTerm=='true') {
      return true;
    } else {
      this.router.navigate(['']);
      return false;
    }
  }




}
