import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../shared/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {AppComponent} from '../../app.component';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['../login/login.component.scss']
})
export class SignupComponent implements OnInit {
  signUpForm: FormGroup;
  projectIcon:any;
  private projectId:any;
  submitted = false;
  private returnUrl: string;
  private error = '';
  loading = false;
  public passwordNotMatch: boolean = false;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
  constructor(
    private svApi: ApiService,
    private router: Router,
    private appComponent: AppComponent,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  )
  {
    this.appComponent.showHeader =false;
  }

  ngOnInit(): void {
    this.appComponent.showHeader =false;
    this.signUpForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.pattern(new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"))]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"))]],
      password2: ['', [Validators.required, Validators.minLength(8)]]
  });
    this.projectIcon=sessionStorage.getItem('projectIcon');
    this.projectId=localStorage.getItem('project');
    //console.log("showHeader: ", this.appComponent.showHeader)
  }

  // convenience getter for easy access to form fields
  get f() { return this.signUpForm.controls; }


  onSubmit() {
    this.passwordNotMatch = false
    this.loading = true;
    this.submitted = true;
    let newUser={email:this.signUpForm.value.username,password:this.signUpForm.value.password,confirmPassword:this.signUpForm.value.password2}
    //console.log(newUser)
    //console.log()
    //console.log(this.signUpForm)
    if (this.f.password.value != this.f.password2.value) {
      this.passwordNotMatch = true;
      this.loading=false;
      return
  }

    if (this.signUpForm.valid) {

      //console.log("valid")
      this.svApi.checkemail(newUser.email).subscribe(
        result=>
        {
          //console.log(result.data)
          if(result.data)
          {
            Swal.fire({
              icon: 'error',
              title: 'Email já registado.',
              text: 'O email inserido já se encontra associado a uma conta.',
            })
            this.router.navigate(["auth/login"]);
          }
          else{
            this.svApi.addUser(this.projectId, newUser).subscribe(
              result=>{
                //console.log(result)
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: "Utilizador registado com sucesso!",
                  allowOutsideClick: false,
                  showConfirmButton:true,
                  timer: 3000,
                  heightAuto: false,
                });
                this.router.navigate(["auth/login"]);
              },
              error=>{
                  Swal.fire({
                    icon: 'error',
                    title: 'Ups, ocorreu um erro.',
                    text: 'Por favor, tente novamente.'
                  })
                  window.location.reload();
                this.loading=false;
              }
            )
          }
        },
        error=>{
          Swal.fire({
            icon: 'error',
            title: 'Problema a verificar o email',
            text: '',
          })
        }
      )

    }
    else{
      this.loading=false;
      //console.log("f: ", this.f)
      //console.log("invalid")
    }

  }

}
