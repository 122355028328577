/*
FORM-BUILDER-DEP-SECTION:  construção da estrutura base do formulário + a secção dependente
Funcionalidades: valida as dependecias entre os elementos
*/


import { Component, Input,OnInit,Output, EventEmitter,ElementRef  } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-form-builder-dep-section',
  templateUrl: './form-builder-dep-section.component.html',
  styleUrls: ['./form-builder-dep-section.component.scss']
})
export class FormBuilderDepSectionComponent implements OnInit {

    @Input() itemQuestions: any;
    @Input() itemQuestionsDepSection: any;
    @Input() submitted: boolean;
    @Input() conditionalItem: string;
    @Input() nextId: number;
    @Input() sectionName: string;
    @Output() response = new EventEmitter();
    @Output() responsebtSubmit = new EventEmitter();

    buttonVisible:boolean=false;
    visibleDepSection:boolean;
    numberRepeat: number;

    newItensDepSection:any=[];
    constructor(private _elementRef: ElementRef) {

    }

    ngOnInit() {
       for (let item of this.itemQuestions)
       {
          if (item.qSynthesis==this.conditionalItem)
          {
            if(item.value!=null)
            {
              this.visibleDepSection=true;
            }
          }
       }
    }

    //every click in form itemQuestions call this function
    changes(item: any)
    {

      //check if the updated item is the conditionalItem
      if(item.qSynthesis==this.conditionalItem)
        {
         if(item.value!=0 && item.value!=null)
         {
          this.buttonVisible=true;
          this.responsebtSubmit.emit(false);
          this.numberRepeat=item.value;

         }
         else{
          this.buttonVisible=false;
          this.responsebtSubmit.emit(true);
          this.numberRepeat=0;
         }
        }

    }

    //build a new depsection multiplied by conditional question number
    goDepSection()
    {
      if(this.checkRequiredItems())
        {
          this.buttonVisible=false;
          this.responsebtSubmit.emit(true);
          //create header of sections
          let title={id:0,label:this.sectionName, name:"title-secção", type:"title", visible: true}
          let objectCopy=JSON.parse(JSON.stringify(this.itemQuestionsDepSection));
          //iterate number of times by the numberRepeat
          for(let i=1; i<=this.numberRepeat;i++)
          {
            title.label=  this.sectionName + " " +i;
            title.id=i;
            let t=JSON.parse(JSON.stringify(title))
            this.newItensDepSection.push(t)
            //iterate items for change the name , the qSynthesis, and ID
            for (let item of objectCopy)
              {

                let newItem=JSON.parse(JSON.stringify(item));
                if(item.type!="matrix" && item.type!="title" && item.type!="sub-title"&& item.type!="paragraph" && item.type!="break" && item.type!="space")
                {
                  newItem.name= item.name+"_"+"S"+i;
                  newItem.qSynthesis= "S"+i+"_"+item.qSynthesis
                  newItem.id=this.nextId++;
                  if(newItem.dependencies==true)
                    {
                      newItem.depQuetion=newItem.depQuetion+"_"+"S"+i;
                    }
                }
                else if (item.type=="matrix")
                {
                  newItem.name= item.name+"_"+"S"+i;
                  let rowsCopy=JSON.parse(JSON.stringify(newItem.values[1].rows));
                  for(let x=0; x<rowsCopy.length; x++)
                  {
                    let newR=Object.assign({},rowsCopy[x]);
                    newR.qSynthesis= "S"+i+"_"+rowsCopy[x].qSynthesis;
                    newR.id=this.nextId++
                    rowsCopy[x]=newR;
                  }
                  newItem.values[1].rows=rowsCopy;
                }
                this.newItensDepSection.push(newItem)
            }

          }


         this.itemQuestionsDepSection=this.newItensDepSection;



         //event to give back the new object of depSection
         this.response.emit(this.itemQuestionsDepSection)
         this.visibleDepSection=true;
        }
        else{

        }

    }

    onFailScrollToElement(element: HTMLElement) {
      element.scrollIntoView({ behavior: "smooth", block: 'center' });
    }
    validateEmail(email)
    {
    var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
    if (reg.test(email)){
    return true; }
    else{
    return false;
    }
    }

    //check if all required items are fullfill
    checkRequiredItems()
    {
      let validationArray = this.itemQuestions;
      let valid = true;
      validationArray.forEach(field => {
        let countTrue = 0;
        let countInvalid = 0;

        let countRadio = 0;
        if (field.required && !field.value && field.type != "checkbox" && field.type != "number" && field.type != "matrix" && field.visible==true) {
          Swal.fire({
            icon: 'error',
            title: 'Erro! Por favor complete todas as respostas!',
            text: '' + field.label,
          })
          valid = false;
          return false;
        }
        else if (field.required && field.visible==true && field.type == "checkbox") {
          for (let v of field.values) {
            if (v.selected == true) {
              countTrue = 1;
            }

          }
          if (countTrue == 0) {
            Swal.fire({
              icon: 'error',
              title: 'Erro! Por favor complete todas as respostas!',
              text: '' + field.label,
            })
            valid = false;
            return false;
          }
        }
        else if (field.type == "number" && field.required&& field.visible==true&& ((field.value < JSON.parse(field.min) || field.value > JSON.parse(field.max)) || field.value==null)) {
          Swal.fire({
            icon: 'error',
            title: 'Erro! Por favor complete todas as respostas!',
            text: '' + field.label + '. Valor min: '+field.min+' valor max: '+field.max,
          })
          valid = false;
          return false;
        }
        else if (field.type == "matrix") {
          for (let r of field.values[1].rows) {
            if (r.valueLine != undefined) {
              countRadio = 1;
              //lblRadio = r.label;
            }
            else if(r.required==true && field.visible==true) {
              if (countInvalid == 0) {
                let _this = this;
                let xx = this._elementRef.nativeElement.querySelector("#radio_" + r.id); // to be worked, only works for radios in matrix elements
                Swal.fire({
                  icon: 'error',
                  title: 'Erro! Por favor complete todas as respostas!',
                  text: '' + r.label,
                  heightAuto: false
                }).then(function () {
                  // scroll to failed question
                  setTimeout(() => {
                    _this.onFailScrollToElement(xx);
                  }, 500);
                })
                countInvalid++;
              }
              valid = false;
              return false;
            }
          }
        }

        else if(field.type== "email" && field.required && field.visible==true)
        {
          if(!this.validateEmail(field.value))
          {
            Swal.fire({
              icon: 'error',
              title: 'Erro! Email inválido',
              text: '' + field.label,
            })
            valid = false;
            return false;
          }
        }

      });
      if (!valid) {
        return false;
      }

      else {
        return true
      }
    }

    putVisibleFalse(item)
    {
      item.visible= false;

    }
    putVisibleTrue(item)
    {
      item.visible= true;

    }


}
