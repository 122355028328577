/*
App- Início da aplicação
Verifica se o url tem o parametro "id", senão verifica se já existe em variavel de sessão a variável "project"
1-Recebe o parametro "id" através do url ou a variavel de sessão "project";
2-Guarda em variavel de sessão esse id na váriavel "project";
3-Realiza o pedido do projeto através do método "getProjectByID"
4-Atribui os valores as váriaveis SCSS
5-Reencaminha para a página do CI
*/


import { Component,OnInit, OnChanges } from '@angular/core';
import {ApiService} from './shared/services/api.service';
import { Router, Routes,ActivatedRoute, Data  } from '@angular/router';
import Swal from 'sweetalert2';
import {AccountService} from './auth/services/authentication.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'FormView';
  theme:any;
  projectID: any;
  project: any={};
  load=false;
  isStylesLoaded = false;
  public showHeader=true;
  public auth:boolean=false;

  constructor(private svApi: ApiService,private activeRoute: ActivatedRoute, private route: Router, private accountService: AccountService)
  {

  }


  ngOnInit(): void {
      this.activeRoute.queryParams.subscribe(
        params => {
          ////console.log('paramsID: ', params['id'])
          if(params['id']!=undefined)
          {
            this.projectID = params['id']
            localStorage.setItem('project', this.projectID)


            this.svApi.getThemeByProjectID(this.projectID).subscribe(
              result=>{
               this.theme=result.data;


              //if the project has "prochild" or undefined icon, the ProChild icon is assigned
              if(this.theme.iconHeader=="prochild"|| this.theme.iconHeader==undefined)
              {
                this.project.icon="assets/img/logo-projeto-header-PT.svg";
                sessionStorage.setItem('projectIcon', this.project.icon)
              }
              else{
                this.project.icon=this.theme.iconHeader;
              }
              if(this.theme.footer=="prochild"|| this.theme.footer==undefined)
              {
              this.project.footer="assets/img/logos-footer.png";
              }
              else{
                this.project.footer=this.theme.footer;
              }

               sessionStorage.setItem('projectIcon', this.project.icon)
               const headerColor = this.theme.header;
               const bodyColor = this.theme.background;
               const footerColor = this.theme.header;
               const textColor = this.theme.text;
               const menuColor = this.theme.menu;
               const iconColor = this.theme.icon;

               const stylesheet = document.createElement('link');

                //set scss variables to value received of API
               stylesheet.addEventListener('load', () => {
                 document.documentElement.style.setProperty('--header-color', headerColor);
                 document.documentElement.style.setProperty('--body-color', bodyColor);
                 document.documentElement.style.setProperty('--footer-color', footerColor);
                 document.documentElement.style.setProperty('--text-color', textColor);
                 document.documentElement.style.setProperty('--menu-color', menuColor);
                 document.documentElement.style.setProperty('--icon-color', iconColor);

                 this.isStylesLoaded = true;
               });
               let style = 'remote-style';
               stylesheet.rel = 'stylesheet';
               stylesheet.href = style + '.css';
               document.getElementsByTagName('head')[0].appendChild(stylesheet);
               if(this.route.url.substring(0,16)!="/auth/recover-pw?")this.checkAuth(this.projectID);



              }

            )

          }
          else if (localStorage.getItem('project')){
            this.projectID= localStorage.getItem('project')

            this.svApi.getThemeByProjectID(this.projectID).subscribe(
              result=>{
                this.theme=result.data;
                //if the project has "prochild" or undefined icon, the ProChild icon is assigned
                if(this.theme.iconHeader=="prochild"|| this.theme.iconHeader==undefined)
                {
                  this.project.icon="assets/img/logo-projeto-header-PT.svg";
                  sessionStorage.setItem('projectIcon', this.project.icon)
                }
                else{
                  this.project.icon=this.theme.iconHeader;
                }

                if(this.theme.footer=="prochild"|| this.theme.footer==undefined)
                {
                 this.project.footer="assets/img/logos-footer.png";
                }
                else{
                  this.project.footer=this.theme.footer;
                }

                sessionStorage.setItem('projectIcon', this.project.icon)
                const headerColor = this.theme.header;
                const bodyColor = this.theme.background;
                const footerColor = this.theme.header;
                const textColor = this.theme.text;
                const menuColor = this.theme.menu;
                const iconColor = this.theme.icon;
                const stylesheet = document.createElement('link');

                stylesheet.addEventListener('load', () => {
                  document.documentElement.style.setProperty('--header-color', headerColor);
                  document.documentElement.style.setProperty('--body-color', bodyColor);
                  document.documentElement.style.setProperty('--footer-color', footerColor);
                  document.documentElement.style.setProperty('--text-color', textColor);
                  document.documentElement.style.setProperty('--menu-color', menuColor);
                  document.documentElement.style.setProperty('--icon-color', iconColor);
                  this.isStylesLoaded = true;
                });
                let style = 'remote-style';
                stylesheet.rel = 'stylesheet';
                stylesheet.href = style + '.css';

                document.getElementsByTagName('head')[0].appendChild(stylesheet);

                if(this.route.url.substring(0,16)!="/auth/recover-pw")this.checkAuth(this.projectID);

              }
            )

          }
          else{
            //console.log("else")

          }
        },
        error=>{
          Swal.fire({
            icon: 'error',
            title: 'Erro! ',
            text: 'Erro na identificação do Projeto' + error,
          })
        });
    }

  checkAuth(projectID){

    this.svApi.checkProjectRequireAuthByID(projectID).subscribe(
      result=>{
          let auth= result.data;
          //console.log("auth", auth)
          if(auth)
          {
            this.auth=true;
            if(this.accountService.isUserLoggedIn()&& sessionStorage.getItem('logged'))
            {
              this.svApi.getSignedTerm().subscribe(
                result=>{
                  let signed=result.data;
                  if(signed)
                  {
                    sessionStorage.setItem('signedTerm','true')
                    this.route.navigate(['home']);
                  }
                  else{
                    sessionStorage.setItem('signedTerm','false')
                    this.route.navigate(['ci-auth']);
                  }
                })
            }
            else{
              this.route.navigate(['auth/login']);
            }

          }
          else{

          if(sessionStorage.getItem("signedTerm")=='true'&&this.accountService.isUserLoggedIn())
          {
            this.route.navigate(['home']);
          }
          else if((sessionStorage.getItem("signedTerm")=='true'&&!this.accountService.isUserLoggedIn()))
          {
            sessionStorage.setItem('signedTerm', 'false');
            this.route.navigate(['ci']);
          }
          else{
            this.route.navigate(['ci']);
          }
          }
      }
    )


  }

  logout(){
    Swal.fire({
      title: "Tem a certeza que pretende terminar sessão?",
      showDenyButton: true,
      showCancelButton: false,
      allowOutsideClick: false,
      confirmButtonColor: '#41BBC9',
      denyButtonColor: '#E5004E',
      confirmButtonText: "Sim",
      denyButtonText: "Não",
      customClass: {
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        denyButton: 'order-3',
      }
    }).then(
      (result) => {
        localStorage.removeItem('token');
        sessionStorage.clear();
       this.route.navigate(['/']);

      }
    );

  }


}
