// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
//localhost
/*
  ApiFormViewPrivate:"http://api.prochildforms.prochild.local/Api/Private/",
  ApiFormViewPublic:"http://api.prochildforms.prochild.local/Api/Public/",
  //ApiFormView:"http://localhost:5002/api/", //localhost
  //ApiFormViewPrivate: "https://localhost:44340/Api/Private/",
  //ApiFormViewPublic: "https://localhost:44340/Api/Public/",
*/
//local
/*
  ApiFormViewPrivate:"http://api.prochildforms.prochild.local/Api/Private/", //local
  ApiFormViewPublic:"http://api.prochildforms.prochild.local/Api/Public/", //local
  Uri:"http://formview.prochild.local",
*/


  //externo v2
  ApiFormViewPrivate:"https://api.prochildforms.prochildcolab.pt/Api/Private/", //local
  ApiFormViewPublic:"https://api.prochildforms.prochildcolab.pt/Api/Public/", //local
  Uri: "https://prochildforms.prochildcolab.pt",

/**/

  codeToken:'fe4f8fa019f9484fa0b030d9763abd7e',
  RequireHttps: false,
  RecaptchaV2: "6Lck17AeAAAAAFZQGU2IcyVBLB_uBJM9PsCDqPU0"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
