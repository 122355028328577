<!-- <div class="container">
<div class="row rIcon">
    <div class="col colIcon">
        <img style="width: 200px;" [src]="projectIcon" />
    </div>
</div>
<div class="row">
    <div class="col">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
            <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
          </svg>
    </div>
    <div class="col">
        <input class="inputUser"> 
    </div>
</div>
<div class="row">
    <div class="col">
       <img src = "../key-fill.svg" alt="key">
    </div>
    <div class="col">
        <input class="inputPw"> 
    </div>
</div>
<div class="row"><i class="fas fa-key"></i>
    <i style="font-size:40px; color: red;" class="bi bi-key-fill"></i></div>
<div class="row"></div>
<div class="row"><button>CRIAR CONTA</button></div>
</div> -->


<!-- <div class="!sucesslogin"> -->
    <div class=" container loginSucess">
      <div class="row justify-content-center">
        <div class="col" style="padding: 0;">
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <!-- box -->
            <div class="formInputBox">
              <!-- img -->
              <div class="row" style="padding: 4.5rem;">
                <div class="col" style="text-align: center;">      
                    <img style="width: 200px;" [src]="projectIcon" />
                </div>
              </div>
              <!-- content form -->
              <div class="row" style="padding: 1rem;">
                <div class="col" style="text-align: center;">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text"
                        style="padding:5px !important; border: 0 !important; background-color: inherit !important;">
                        <i style="font-size:40px;" class="bi bi-person-circle"></i>
                      </span>
                    </div>
                    <input placeholder="E-mail" type="text" formControlName="username" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                      <div *ngIf="f.username.errors.required">Utilizador requerido</div>
                    </div>
                  </div>
                  <div class="input-group mb-3" style="">
                    <div class="input-group-prepend">
                        <span class="input-group-text"
                        style="padding:5px !important; border: 0 !important; background-color: inherit !important;">
                        <i style="font-size:40px;" class="bi bi-key-fill"></i>
                      </span>
                    </div>
                    <input placeholder="Password" type="password" formControlName="password" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                      <div *ngIf="f.password.errors.required">Password requerida</div>
                    </div>
                  </div>
                <!-- recover pw -->
                <div class="row" style="margin-top: 5px; padding-bottom: 2rem;">
                        <div class="col" style="text-align: right;">
                        <span><a style="text-decoration: none; font-size: 16px;" routerLink="/auth/recover">Recuperar palavra-passe </a></span>
                        </div>
                    </div>
                  <div class="row" style="">
                    <div class="col-12" style="text-align: center;">
                      <button [disabled]="loading" class="btForm">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                       INICIAR SESSÃO
                      </button>
                    </div>
                  </div>
                     <!-- box -->
      
                <div class="row" style="padding: 2rem;">
                    <div class="col" style="text-align: center; color: #5E5D5D;font-size: 16px;">
                        Ainda não está registado? <a style="text-decoration: none;font-size: 16px; font-weight: bold;" routerLink="/auth/signup">Registe-se!</a>
                       
                    </div>
                </div>  
               
                </div>
              </div>
             
            </div>
         
         
          
          </form>
        </div>
      </div>
    </div>
<!-- </div> -->
  