import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() { }

  private msgForm = new BehaviorSubject({formID:'default', phaseID:'default'});
    currentmsgForm = this.msgForm.asObservable();

    changeForm(newForm: any, newPhase: any) {
      this.msgForm.next({formID:newForm, phaseID:newPhase});
  }  
}
