/*
CI- consentimento informado
Funcionalidades: responsavel por contruir a página CI
-utiliza o innerHtml para construir baseado em variavel html;
-constrói listagem de checkbox para validação + a validação recaptcha da google
-possibilita o donwload de ficheiro pdf relativo ao CI anexado ao projeto
-após submissão da validação do CI é criado um novo user e guardado em váriavel de sessão (userID)
-criada variavel de sessão (signedTerm) para verficar se o CI já foi validado
*/
import { Component, OnInit, ElementRef, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {ApiService} from '../shared/services/api.service';
import {DataService} from '../shared/services/data.service';
import { first } from 'rxjs/operators';
import {AppComponent} from '../app.component';

import {environment} from '../../environments/environment';
import Swal from 'sweetalert2';
import {AccountService} from '../auth/services/authentication.service'

@Component({
  selector: 'app-ci',
  templateUrl: './ci.component.html',
  styleUrls: ['./ci.component.scss']
})
export class CiComponent implements OnInit {
  public loading=false;
  CiID:any;
  dataIsNull:boolean=true;
  htmlContent:any;
  checkList:any;
  public ciValidation: any;
  projectID:any;
  requiredDownload:boolean=false;
  ci:any;
  loadUserDetails: boolean=false;
  signedTerm:boolean=false;
  dateTime= new Date();
  user:any;
  reCaptchaKey:string;
  captcha:any;
  theme:any;
  downloadedCi:boolean= false; //for check if downloaded CI
  private codeToken={token:''};
  private needAuth:boolean=false;
  public haveFile:boolean=false;



  constructor( private svApi: ApiService, private authenticationService: AccountService,private svData: DataService, private appComponent: AppComponent,private route: Router) {
//console.log(sessionStorage.getItem("signedTerm"))
//console.log(sessionStorage.getItem("logged"))
    this.appComponent.showHeader =true;
    this.loading=true;
    this.projectID= localStorage.getItem('project')
    this.reCaptchaKey=environment.RecaptchaV2;
    this.codeToken.token=environment.codeToken;


    //check if CI already Check, if yes - get user info, if not- get CI in empty view
    if (sessionStorage.getItem("signedTerm"))
    {
      this.signedTerm=true;
      // if signedTerm,  then get userConfig details (for put the value in date signedTerm)
      this.svApi.getUser().subscribe(
        result=>{
          //console.log("getUser: ", result)
          this.user=result;
          this.loadUserDetails=true;

          this.route.navigate(['home'])

        },
        error=>
        {
          //console.log("error get user")
          Swal.fire({
            icon: 'error',
            title: 'Erro! ',
            text: 'Erro ao obter utilizador',
          })
        }
      )
      this.loading=false;
    }
    else{
      this.svApi.getCiByProjectID(this.projectID).subscribe(
        result=>{
          //console.log("CI: ", result);
          if(result.data.resources.length>0)
          {
            this.ci=result.data.resources[0];
            this.checkList=JSON.parse(this.ci.checkList);
            this.htmlContent=this.ci.html;
            this.requiredDownload=result.data.requiredDownload;
            if(this.ci.fileName!="" && this.ci.fileName!=undefined && this.ci.fileName!=null) this.haveFile=true;
            this.dataIsNull=false;
            this.loading=false;

          }
          else{
            this.dataIsNull=true;
            this.loading=false;
          }



        },
        error=>{
          Swal.fire({
            icon: 'error',
            title: 'Erro! ',
            text: 'Erro no acesso ao CI.' + error,
          })
        }
      )
    }
    if (sessionStorage.getItem("downloadedCi"))
    {
      this.downloadedCi=true;
    }
  }

  ngOnInit(): void {

  }

  //function for donwload CI file or show msg "Não tem nenhum documento anexado"
  public downloadAsPDF() {
    this.loading=true;
    this.svApi.getCiFile(this.projectID,this.ci.id).subscribe(
      result=>{
        /* if want open in new tab
        var fileURL = window.URL.createObjectURL(file);
        let tab:any = window.open();
        tab.location.href = fileURL;
        */
        var newBlob = new Blob([result], { type: "application/pdf" });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }
            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download = this.ci.fileName;
            // this is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            sessionStorage.setItem("downloadedCi", "true")
            this.downloadedCi=true;
        this.loading=false;
      },
      error=>{
        //console.log(error)
        Swal.fire({
          icon: 'error',
          title: 'Erro! ',
          text: 'Erro ao obter ficheiro'+error,
        })
      })

  }
  //function for donwload CI file or show msg "Não tem nenhum documento anexado"
  public downloadAsPDFAndGo() {
    this.loading=true;
    this.svApi.getCiFile(this.projectID,this.ci.id).subscribe(
      result=>{
        /* if want open in new tab
        var fileURL = window.URL.createObjectURL(file);
        let tab:any = window.open();
        tab.location.href = fileURL;
        */
        var newBlob = new Blob([result], { type: "application/pdf" });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }
            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download = this.ci.fileName;
            // this is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            sessionStorage.setItem("downloadedCi", "true")
            this.downloadedCi=true;
            this.submit();
        this.loading=false;
      },
      error=>{
        //console.log(error)
        Swal.fire({
          icon: 'error',
          title: 'Erro! ',
          text: 'Erro ao obter ficheiro'+error,
        })
      })
  }

//verify if any value is false
  haveFalse(element, index, array) {
    return element ==false;
  }

//verify if any value is undefined
  haveUndefined(element, index, array) {
    return element ==undefined;
  }


//each click checks any false or undefined value
  checkClick(value, i){
    let list = this.checkList;
    list[i].check=!value;
    let array:any=[]
    for(let check of this.checkList)
    {
      array.push(check.check)
    }
    if(array.some(this.haveFalse)==false && array.some(this.haveUndefined)==false)
    {
      this.ciValidation=true;
    }
    else{
      this.ciValidation=false;
    }

  }

  //submit CI validation, create a new user
  async submit(){
    this.loading=true;

    //If submit ci -> create a new user, save the id of new user in sessionStorage and go home
    try
    {
      const result= await this.authenticationService.loginByToken(this.projectID,this.codeToken)
      if (result==true)
      {
        sessionStorage.setItem("logged", 'true')
        sessionStorage.setItem("signedTerm", "true")
        this.loading = false;
        this.route.navigate(['home']);
      }
  //console.log(`Login efetuado: ${result}`);
    }
    catch (error)
    {
      Swal.fire({
        icon: 'error',
        title: 'Login inválido! ',
        text: 'Erro a criar utilizador',
      })
        this.loading = false;
    }
  }



}
