import { Component, Input, OnInit } from '@angular/core';


@Component({
    selector: 'app-form-email-child',
    template: `
    <div class="box">
    <div class="form-group">
    <i class="far fa-envelope yellow faIcon"></i>
    <label class="questionLbl">{{item.label}}</label> 
    <label *ngIf="item.required" class="text-danger">*</label>
    <button *ngIf="item.help.length!=0" style="background:none; border:none; color: #233a80;" 
    tooltip="{{item.help}}">
    <i class="far fa-question-circle"></i>
</button>
    <input type="email" 
    class="form-control inputT" 
    id="{{item.name}}" 
    placeholder="{{item.placeholder}}"
    [(ngModel)]="item.value"
    #emailref="ngModel"
    #email="ngModel" 
    [ngModelOptions]="{standalone: true}" 
    autocomplete="off"
    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" id="email"name="email" ngModel #emailref="ngModel">
    </div>
    <div *ngIf="emailref.errors &&(emailref.touched || emailref.dirty)" class ="alert alert-danger">
    <div [hidden]="!emailref.errors?.pattern">
      Email inválido!
    </div> 
   </div> 

    `,
    styleUrls: ['../elementsStyle.scss'],
    providers: []
})

export class EmailChildComponent implements OnInit {

    
    @Input() itemValue: any;
    @Input() itemRequired: any;
    @Input() itemName: any;
    @Input() itemLabel: any;
    @Input() item: any;
    @Input() itemMax: any;
    @Input() itemPlaceholder: any;


    constructor() { }

    ngOnInit() {

    }

}