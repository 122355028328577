import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ApiService } from '../shared/services/api.service';
import {  Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })

export class HomeResolver implements Resolve<Observable<any | boolean>> {
	
	constructor(private svApi: ApiService) {}
	
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any | boolean> {	
        return this.svApi.getUserStatus(); 
	}
	
}