/*
FORM-BUILDER:  construção da estrutura base do formulário
Funcionalidades: valida as dependecias entre os elementos 
*/

import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-form-builder',
    templateUrl: './form-builder.component.html',
    styleUrls: ['./form-builder.component.scss'],
    providers: []
})

export class FormBuilderComponent implements OnInit {

    
    @Input() itemQuestions: any;
    @Input() submitted: boolean;

    constructor() { 
       
    }

    ngOnInit() {
       
    }

    putVisibleFalse(item)
  {
    item.visible= false;

  }
  putVisibleTrue(item)
  {
    item.visible= true;

  }

   
}