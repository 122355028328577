import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent} from './home/home.component';
import { HomeResolver} from './home/home.resolver';
//import { FormComponent} from './form/form.component';
import { FormComponent} from './forms/form.component';
import { FormResolver} from './forms/form.resolver';
import {CiComponent} from './ci/ci.component';
import {CiWithLoginComponent} from './ci-with-login/ci-with-login.component';
import {AppComponent} from './app.component';
import {LoginComponent} from './auth/login/login.component';
import {RecoverComponent} from './auth/recover/recover.component';
import {RecoverPWComponent} from './auth/recover-pw/recover-pw.component';
import {SignupComponent} from './auth/signup/signup.component';
import {AuthGuard} from './auth/services/auth.guard'

const routes: Routes = [
  {path:'',component:AppComponent},
  {path:"ci", component:CiComponent},
  {path:"ci-auth", component:CiWithLoginComponent},
  {path:"auth/login", component:LoginComponent},
  {path:"auth/recover", component:RecoverComponent},
  {path:"auth/recover-pw", component:RecoverPWComponent},
  {path:"auth/signup", component:SignupComponent},
  {path:"home", component:HomeComponent, canActivate: [AuthGuard],  resolve: { status: HomeResolver }},
  {path:"form/:id", component:FormComponent, canActivate: [AuthGuard], resolve: { formData: FormResolver}},
  //{path:"form/:id", component:FormComponent, canActivate: [AuthGuard]},
  { path: '**', redirectTo: '' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
