<!-- BREAK -->
<div *ngIf="itemType=='break'">
  <div><hr /></div>
</div>

<!-- SPACE -->
<div *ngIf="itemType=='space'">
  <div style="height: 50px;"></div>
</div>

<!-- TITLE -->
<div *ngIf="itemType=='title'">
  <app-form-title [itemLabel]="item.label"> </app-form-title>
</div>

<!-- SUBTITLE-->
<div *ngIf="itemType=='sub-title'" style="padding-top: 5px;">
  <app-form-subtitle [itemLabel]="item.label"> </app-form-subtitle>
</div>

<!-- LINK    -->
<div *ngIf="itemType=='link'">
  <app-form-link [item]="item"  > </app-form-link> 
 
</div> 

<!-- QUESTION-TITLE-->
<div *ngIf="itemType=='questionTitle'">
  <app-form-questiontitle [itemLabel]="item.label"> </app-form-questiontitle>
</div>

<!-- TEXTAREA -->
<div *ngIf="itemType=='textarea'">
  <app-form-textarea [item]="item" [itemQuestions]="itemQuestions" [itemName]="item.name" [itemLabel]="item.label" [itemRequired]="item.required" [itemDependencies]="item.dependencies"> </app-form-textarea>
</div>

<!-- TEXT -->
<div *ngIf="itemType=='text'">
  <app-form-text [item]="item" [itemQuestions]="itemQuestions" [itemName]="item.name" [itemLabel]="item.label" [itemRequired]="item.required" [itemDependencies]="item.dependencies" [submitted]="submitted"> </app-form-text>
</div>

<!-- EMAIL -->
<div *ngIf="itemType=='email'">
  <app-form-email [item]="item" [itemQuestions]="itemQuestions" [itemName]="item.name" [itemLabel]="item.label" [itemRequired]="item.required" [itemDependencies]="item.dependencies"></app-form-email>
</div>

<!-- NUMBER -->
<div *ngIf="itemType=='number'">
  <app-form-number [item]="item" [itemQuestions]="itemQuestions" [itemName]="item.name" [itemLabel]="item.label" [itemRequired]="item.required" [itemDependencies]="item.dependencies" [submitted]="submitted"></app-form-number>
</div>

<!-- CHECKBOX -->
<div *ngIf="itemType=='checkbox'">
  <app-form-checkbox [itemName]="item.name" [itemLabel]="item.label" [itemRequired]="item.required" [itemDependencies]="item.dependencies" [item]="item" [submitted]="submitted"></app-form-checkbox>
</div>

<!-- RADIO -->
<div *ngIf="itemType=='radio'">
  <app-form-radio [item]="item" [itemQuestions]="itemQuestions" [itemName]="item.name" [itemLabel]="item.label" [itemRequired]="item.required" [itemDependencies]="item.dependencies" [submitted]="submitted"></app-form-radio>
</div>

<!-- ORDERITEM -->
<div *ngIf="itemType=='orderItem'">
  <app-form-orderItem [item]="item" [itemQuestions]="itemQuestions" [itemName]="item.name" [itemLabel]="item.label" [itemRequired]="item.required" [itemDependencies]="item.dependencies" [submitted]="submitted"></app-form-orderItem>
</div>

<!-- MATRIX -->
<div *ngIf="itemType=='matrix'">
  <app-form-matrix [itemColumns]="item.values[0].columns" [itemRows]="item.values[1].rows" [item]="item" [submitted]="submitted"></app-form-matrix>
</div>

<!-- Select -->
<div *ngIf="itemType=='autocomplete'">
  <app-form-select [item]="item" [itemQuestions]="itemQuestions" [itemName]="item.name" [itemLabel]="item.label" [itemRequired]="item.required" [itemDependencies]="item.dependencies" [submitted]="submitted"></app-form-select>
</div>

<!-- Date -->
<div *ngIf="itemType=='date'">
 <app-form-date [item]="item" [itemQuestions]="itemQuestions" [itemName]="item.name" [itemLabel]="item.label" [itemRequired]="item.required" [itemDependencies]="item.dependencies" [submitted]="submitted"></app-form-date>
</div>

<!-- Paragraph -->
<div *ngIf="itemType=='paragraph'">
  <app-paragraph [item]="item"  [itemName]="item.name" [itemLabel]="item.label"  [itemDependencies]="item.dependencies" [submitted]="submitted"></app-paragraph>
 </div>
 