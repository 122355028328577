import { Component, Input, OnInit , TemplateRef} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
    selector: 'app-form-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['../elementsStyle.scss'],
    providers: []
})

export class CheckboxComponent implements OnInit {

    @Input() itemRequired: any;
    @Input() itemName: any;
    @Input() itemDependencies: any;
    @Input() itemLabel: any;
    @Input() item: any;
    @Input() submitted: any;
    template: TemplateRef<any>;
    modalRef: BsModalRef;
    imageModel:any;

    constructor(private modalService: BsModalService) { }

    toggleValue(item) {
        item.selected = !item.selected;
      }

    ngOnInit() {
    }
    openImage(template: TemplateRef<any>, image) 
    {
        this.imageModel=image;
            this.modalRef = this.modalService.show(
              template
            );
    }

}