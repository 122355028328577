import { Component, Input, OnInit, TemplateRef} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
    selector: 'app-form-orderItem',
    template: `
    <div class="box">
    <label class="questionLbl"><i class="fas fa-sort-alpha-down-alt yellow faIcon paddingIconR"></i> {{item.label}} <label *ngIf="item.required" class="text-danger">*</label></label>  
    <button *ngIf="item.help.length!=0" style="background:none; border:none; color: #233a80;" 
    tooltip="{{item.help}}">
    <i class="far fa-question-circle"></i>
    </button>
    <p>
    <img *ngIf="item.haveImage" [src]="item.ImageBase64" (click)="openImage(template, item.ImageBase64)" style="max-width: 300px;max-height: 300px;cursor:zoom-in;" class="img-responsive">

    </p>
    <div *ngFor="let v of item.values" class="inline-form-group" >
                                        <div class="row rI boxRandC " *ngIf="v.haveImage">
                                          <div class="col-3 vertical-align">                     
                                          <img  (click)="openImage(template, v.ImageBase64)" [src]="v.ImageBase64" class="img-responsive imgView">
                                          </div>  
                                            <div class="col-8 vertical-align">
                                                <label style="color: #5E5D5D;margin-bottom:0px;">{{v.label}}</label>
                                            </div>
                                            <div class="col-1 vertical-align" style="text-align:right;">                                           
                                                <div class="row justify-content-end"><i class="fas fa-arrow-up mouseHover blue" (click)="up(v, item.values)" [hidden]="submitted"></i>
                                               <i class="fas fa-arrow-down mouseHover blue" style="padding-left:10px;" (click)="down(v, item.values)" [hidden]="submitted"></i></div>
                                            </div>
                                        </div>
                                        <div class="row rI boxRandC " *ngIf="!v.haveImage">
                                       
                                            <div class="col-11 vertical-align">
                                                <label style="color: #5E5D5D;margin-bottom:0px;">{{v.label}}</label>
                                            </div>
                                            <div class="col-1 vertical-align" style="text-align:right;">                                           
                                                <div class="row justify-content-end"><i class="fas fa-arrow-up mouseHover blue" (click)="up(v, item.values)" [hidden]="submitted"></i>
                                               <i class="fas fa-arrow-down mouseHover blue" style="padding-left:10px;" (click)="down(v, item.values)" [hidden]="submitted"></i></div>
                                            </div>
                                        </div>
                                       
                                    </div>

    </div>
    <ng-template #template>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Image</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true" style="font-size: 2rem;
        font-weight: initial;">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="text-align: center;">
        <img [src]="imageModel" style=" max-height: 100%;max-width: 100%;cursor: pointer; ">
    </div>
    <div class="modal-footer">

    </div>
   
  </ng-template>
    `,
    styleUrls: ['../elementsStyle.scss'],
})

export class OrderItensComponent implements OnInit {

  @Input() item: any;
  @Input() itemRequired: any;
  @Input() itemName: any;
  @Input() itemLabel: any;
  @Input() itemPlaceholder: any;
  @Input() submitted: boolean;
  template: TemplateRef<any>;
    modalRef: BsModalRef;
    imageModel:any;

    constructor(private modalService: BsModalService) { }

  ngOnInit() {

  }

  up(item, listItems)
  {
   
    var pos = listItems.indexOf(item);
    if(listItems.indexOf(item)!=0)
    {
     
      this.changePosition(listItems, (pos-1),pos);
     
    }
    
  }

  down(item, listItems)
  {
    if(listItems.indexOf(item)!=(listItems.length-1))
    {
      var pos = listItems.indexOf(item);
      this.changePosition(listItems, (pos+1),pos);
    }
  }

  changePosition(arr, from, to) {
    arr.splice(to, 0, arr.splice(from, 1)[0]);
    return arr;
  };

  openImage(template: TemplateRef<any>, image) 
    {
        this.imageModel=image;
            this.modalRef = this.modalService.show(
              template
            );
    }

}
