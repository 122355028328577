import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot , Router} from '@angular/router';
import { ApiService } from '../shared/services/api.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })

export class FormResolver implements Resolve<Observable<any>> {
    constructor(private svApi: ApiService, private router: Router) { 
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> { 
        return this.svApi.getPhaseFormByID(route.params.id);    
    }
}