import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from "@auth0/angular-jwt";
import {ApiService} from '../../shared/services/api.service';
const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AccountService {


  constructor(private http: HttpClient, private svApi: ApiService) { }

  async loginByUserPw(projectId, user) {

    const result = await this.http.post<any>(`${environment.ApiFormViewPublic}Project/${projectId}/Login`, user).toPromise();
    if (result && result.token) {
      window.localStorage.setItem('token', result.token);
      window.sessionStorage.setItem("userID", result.userId)
      return true;
    }

    return false;
  }

  async loginByToken(projectId, token) {
    const result = await this.http.post<any>(`${environment.ApiFormViewPublic}Project/${projectId}/LoginByToken`,token).toPromise();
    if (result && result.token) {
      window.localStorage.setItem('token', result.token);
      window.sessionStorage.setItem("userID", result.userId)
      return true;
    }

    return false;
  }

  async createAccount(account: any) {
    const result = await this.http.post<any>(`${environment.ApiFormViewPublic}/users`, account).toPromise();
    return result;
  }

  getAuthorizationToken() {
    const token = window.localStorage.getItem('token');
    return token;
  }

  getTokenExpirationDate(token: string): Date {
    const decoded: any = helper.decodeToken(token);
    if (decoded.exp === undefined) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }


  isTokenExpired(token?: string): boolean {
    if (!token) {
      sessionStorage.setItem('logged', 'false');
      sessionStorage.setItem('signedTerm', 'false');
      return true;
    }

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) {
      return false;
    }
    return !(date.valueOf() > new Date().valueOf());
  }

  isUserLoggedIn() {
    const token = this.getAuthorizationToken();
    if (!token) {
      return false;
    } else if (this.isTokenExpired(token)) {
      return false;
    }

    return true;
  }

  logout(){

  }
}
